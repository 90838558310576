import React from "react";
import {insertNotification} from "Utils/NotificationsUtils";
import {UserFormFields} from "Pages/Users/UserFormFields";
import {GSPSPageWrapper, GSPSPopUpHeader} from "Components/Common/GSPSStyledContainers.styles";
import {useAddUserMutation} from "api/UserTableAPI";
import PropTypes from "prop-types";

export const UserCreator = ({show, setShow}) => {
    const [addUser, {isLoading, isError}] = useAddUserMutation();

    const onSubmit = async (data) => {
        const updateData = {
            ...data,
            role_id: data.user_title,
            group_permission_id: data.user_group_permission,
            industry_type_id: data.user_industry_type,
        };

        try {
            const jsonRes = await addUser(updateData).unwrap();
            insertNotification("Success", `User ${jsonRes.full_name} has been created ..`, "success");
            setShow(false);
        } catch (error) {
            insertNotification("Error", `can't create user: ${error.message}`, "error");
        }
    };

    return (
        <>
            <GSPSPageWrapper className="show" role="dialog" show={show} centered size={"lg"}>
                <GSPSPopUpHeader>
                    Create User
                </GSPSPopUpHeader>
                <UserFormFields onSubmit={onSubmit} isLoading={isLoading} isError={isError} setShow={setShow} />
            </GSPSPageWrapper>
        </>
    );
};
UserCreator.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
};
