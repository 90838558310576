import {Link} from "react-router-dom"
import {FooterContainer} from "./GSPSFooter.styles"

const GSPSFooter = () => {
    return <FooterContainer>
        <footer className="position-absolute bottom-0 start-0 w-100 z-3">
            <div className="container d-flex justify-content-between align-items-center py-2">
                <div>
                    <h6 className="copyRights m-0">
                        © 2024 SiteVisionPro
                    </h6>
                </div>
                <div>
                    <ul className="list-unstyled d-flex align-items-center m-0">
                        <li className="me-3">
                            <Link to={"#"}>
                                Terms
                            </Link>
                        </li>

                        <li>
                            <Link to={"#"}>
                                Privacy notice
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    </FooterContainer>
}

export default GSPSFooter