import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {GSPSToolbarSelectFieldContainer} from "./GSPSToolbarSelectField.styles";
import GSPSFilterComponent from "./GSPSFilterComponent";

export const GSPSToolbarSelectField = ({
    inputID,
    register,
    selectableOptions = [],
    isRequired = false,
    defaultValue = null,
    value = null, // Use this if you want to control the value from the parent component.
    onChangeCB = null,
    selectOption,
    DropDownReset,
    labelName,
    renderedElementType = "autocomplete",
    updatedAuditFilter,
    disableAutoSorting,
    variant = "standard"
}) => {
    const [selectedOption, setSelectedOption] = useState(defaultValue);
    const [open, setOpen] = useState(false);

    const sortedOptions = disableAutoSorting ? selectableOptions.sort((a, b) => {
        if (a.display_text < b.display_text) return -1;
        if (a.display_text > b.display_text) return 1;
        return 0;
    }) : selectableOptions;

    const handleSelectedOption = (newSelect) => {
        setSelectedOption(newSelect);

        if (onChangeCB) {
            onChangeCB(newSelect);
        }
    };


    useEffect(() => {
        if (DropDownReset) {
            setSelectedOption(defaultValue);
        }
    }, [DropDownReset, defaultValue]);

    return (
        <GSPSToolbarSelectFieldContainer>
            {
                renderedElementType === "select" || renderedElementType === "filter" ? (
                    <FormControl
                        focused
                        className="w-100"
                        variant={variant}
                    >
                        <InputLabel className="label-md">{labelName}</InputLabel>
                        <Select
                            value={selectedOption}
                            onChange={(e) => handleSelectedOption(e.target.value)}
                            label={labelName}
                            displayEmpty
                            inputProps={{"aria-label": "select"}}
                            size="small"
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            open={open}
                            placeholder="test"
                            renderValue={() => renderedElementType === "filter" && ("Filter")}
                            sx={{
                                '& .MuiSelect-select': {
                                    border: 'none',
                                    boxShadow: 'none',
                                },
                                '&:before': {
                                    display: 'none',
                                },
                                '&:after': {
                                    display: 'none',
                                },
                            }}
                        >
                            {
                                renderedElementType === "filter" ? (
                                    <GSPSFilterComponent
                                        setOpen={setOpen}
                                        title={"Select the items to display on the map:"}
                                        options={sortedOptions}
                                        onChange={onChangeCB}
                                        updatedState={updatedAuditFilter}
                                    />
                                ) : (
                                    sortedOptions.map((item) => (
                                        <MenuItem key={item.key} value={item.value}>
                                            {item.display_text}
                                        </MenuItem>
                                    ))
                                )
                            }
                        </Select>
                    </FormControl>
                ) : (
                    <Autocomplete
                        className="dropdown"
                        disablePortal
                        options={sortedOptions}
                        getOptionLabel={(option) => typeof option.display_text === "string" ? option.display_text : ""}
                        value={value ? value : selectedOption}
                        onChange={(e, newValue) => handleSelectedOption(newValue)}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={labelName}
                                name={inputID}
                                id={inputID}
                                required={isRequired}
                                focused
                                {...register}
                                variant={variant}
                                InputLabelProps={{
                                    className: 'label-md',
                                }}
                                sx={{
                                    '& .Mui-focused:before': {
                                        display: 'none',
                                    },
                                    '& .Mui-focused:after': {
                                        display: 'none',
                                    },
                                }}
                            />
                        )}
                        fullWidth
                    />
                )
            }
        </GSPSToolbarSelectFieldContainer >
    );
};

GSPSToolbarSelectField.propTypes = {
    inputID: PropTypes.string.isRequired,
    register: PropTypes.object,
    selectableOptions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        display_text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })),
    isRequired: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChangeCB: PropTypes.func,
    selectOption: PropTypes.object,
    DropDownReset: PropTypes.bool,
    labelName: PropTypes.string,
    renderedElementType: PropTypes.oneOf(["autocomplete", "select", "filter"]).isRequired,
    variant: PropTypes.oneOf(["outlined", "standard"]).isRequired,
    updatedAuditFilter: PropTypes.object.isRequired,
    disableAutoSorting: PropTypes.bool,
};
