import React from "react";
import {Col} from "react-bootstrap";
import PropTypes from "prop-types";
import {GSPSSubmitButtonStyle} from "Components/Common/GSPSSubmitButton.styles";

export const GSPSSubmitButton = ({
    buttonText,
    controlSize,
    offset,
    onClick,
    colClassName,
    padding,
    isLoading = false,
    isDisabled = false,
    form,
}) => {
    return <>
        <GSPSSubmitButtonStyle className={`${colClassName} cta-btn`} disabled={isDisabled} type="submit" onClick={onClick} form={form}>
            {buttonText}
            {isLoading &&
                <div className="spinner-border spinner-border-sm" role="status" />
            }
        </GSPSSubmitButtonStyle>
    </>;
};

GSPSSubmitButton.propTypes = {
    buttonText: PropTypes.string.isRequired,
    controlSize: PropTypes.number,
    offset: PropTypes.number,
    onClick: PropTypes.func,
    colClassName: PropTypes.string,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    secondaryBtn: PropTypes.bool,
    padding: PropTypes.number,
    form: PropTypes.string,

};
