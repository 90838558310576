import React, {useEffect, useState} from "react";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {fetchSelectionOptions, humanizeText, makeGetRequest, useIsDesktop} from "Services/ServicesUtils";
import {GSPSToolbarSelectField} from "Components/Common/GSPSToolbarSelectField";
import {ToolbarContainer} from "Pages/Audits/AuditMapToolbar.styles";
import {API_USERS, API_HISTORY} from "Services/Endpoints";
import {API_ACCOUNTS} from "Models/Account/AccountEndpoints";
import {API_LOCATIONS} from "Models/Location/LocationEndpoints";
import {Form} from "react-bootstrap";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {DataGrid} from "@mui/x-data-grid";
import {DatePicker} from "@mui/x-date-pickers";
import {DatePickerContainer} from "Components/Common/DatePicker.styles";
import {capitalize} from "lodash";
import {Backdrop, CircularProgress, Divider, Toolbar, Typography} from "@mui/material";
import {useTheme} from "styled-components";
import GSPSPropDrivenTable from "Components/DataTable/GSPSPropDrivenTable";

const combineAndSortData = (accounts, users, locations) => {
    const combined = [
        ...accounts.map((item, index) => ({...item, listName: "accounts", uniqueId: `account-${item.id}-${index}`})),
        ...users.map((item, index) => ({...item, listName: "users", uniqueId: `user-${item.id}-${index}`})),
        ...locations.map((item, index) => ({...item, listName: "locations", uniqueId: `location-${item.id}-${index}`})),
    ];

    return combined.sort((a, b) => new Date(b.history_date) - new Date(a.history_date));
};



export const ActivityLogs = () => {
    const [users, setUsers] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [combinedHistory, setCombinedHistory] = useState([]);

    const theme = useTheme();

    const adjustedFromDate = fromDate ? new Date(fromDate.setHours(0, 0, 0, 0)).toISOString() : "";
    const adjustedToDate = toDate ? new Date(toDate.setHours(23, 59, 59, 999)).toISOString() : "";

    useEffect(() => {
        fetchSelectionOptions(API_USERS.usersDropdown, setUsers, "full_name");
        fetchSelectionOptions(API_ACCOUNTS.accountsDropdown, setAccounts);
        fetchSelectionOptions(API_LOCATIONS.locations, setLocations, "address.full_address");
    }, []);

    const buildUrlWithParams = (baseUrl, params) => {
        let url;
        if (baseUrl.startsWith("http://") || baseUrl.startsWith("https://")) {
            url = new URL(baseUrl);
        } else {
            const base = window.location.origin;
            url = new URL(baseUrl, base);
        }
        url.search = new URLSearchParams(params).toString();
        return url.toString();
    };

    const handleUserSelection = (user) => {
        if (user && user.value != 0) {
            setSelectedUser(user.value);
        } else {
            setSelectedUser(null);
        }
    };

    const handleAccountSelection = (account) => {
        if (account && account.value != 0) {
            setSelectedAccount(account.value);
        } else {
            setSelectedAccount(null);
        }
    };

    const handleLocationSelection = (location) => {
        if (location && location.value != 0) {
            setSelectedLocation(location.value);
        } else {
            setSelectedLocation(null);
        }
    };

    async function handleSubmit(e) {
        e.preventDefault();
        setTableLoading(true);

        const params = {
            fromDate: adjustedFromDate,
            toDate: adjustedToDate,
        };

        const [accountsHistory, usersHistory, locationsHistory] = await Promise.all([
            makeGetRequest(buildUrlWithParams(API_HISTORY.accounts, {...params, account: selectedAccount ? selectedAccount : ""})),
            makeGetRequest(buildUrlWithParams(API_HISTORY.users, {...params, user: selectedUser ? selectedUser : ""})),
            makeGetRequest(buildUrlWithParams(API_HISTORY.locations, {...params, location: selectedLocation ? selectedLocation : ""}))
        ]);

        const combinedData = combineAndSortData(
            accountsHistory.results, usersHistory.results, locationsHistory.results);

        setCombinedHistory(combinedData);

        setTableLoading(false);
    }

    const historyColumns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
        },
        {
            field: 'listName',
            headerName: 'Category',
            width: 200,
        },
        {
            field: 'history_date',
            headerName: 'Date',
            width: 200,
            type: "dateTime",
            valueGetter: (params) => {
                return new Date(params);
            }
        },
        {
            field: 'history_user_name',
            headerName: 'User Name',
            width: 200,
            renderCell: (params) => {
                return params?.row?.history_user?.full_name || "-"
            }
        },
        {
            field: 'history_user_role',
            headerName: 'User Role',
            width: 200,
            renderCell: (params) => {
                return capitalize(params?.row?.history_user?.role?.name) ?? "-"
            }
        },
        {
            field: 'history_user_group_permission',
            headerName: 'User Permission',
            width: 200,
            renderCell: (params) => {
                return params?.row?.history_user?.group_permission?.name || "-"
            }
        },
        {
            field: 'history_user_industry_type',
            headerName: 'User Industry',
            width: 200,
            renderCell: (params) => {
                return params?.row?.history_user?.industry_type?.name || "-"
            }
        },
        {
            field: 'history_user_mobile_phone',
            headerName: 'User Mobile Phone',
            width: 200,
            renderCell: (params) => {
                return params?.row?.history_user?.mobile_phone || "-"
            }
        },
        {
            field: 'history_user_office_phone',
            headerName: 'User Office Phone',
            width: 200,
            renderCell: (params) => {
                return params?.row?.history_user?.office_phone || "-"
            }
        },
        {
            field: 'history_type',
            headerName: 'Action',
            width: 200,
            valueGetter: (params) => {
                return params === "+" ? "Created" :
                    params === "-" ? "Deleted" :
                        params === "~" ? "Modified" : "-"
            }
        },
        {
            field: 'changed_fields',
            headerName: 'Changed Fields',
            width: 200,
            valueGetter: (params) => {
                return params ? humanizeText(params) : "-"
            }
        },
    ];

    return (
        <div className="history">
            <GSPSBreadCrumbs />

            <div className="container">

                <Form onSubmit={handleSubmit} className="mb-5">
                    <ToolbarContainer>
                        <div className="row py-3 align-items-center">
                            <div className="col-4">
                                {users &&
                                    <GSPSToolbarSelectField
                                        defaultValue={{key: "DefaultValue", value: 0, display_text: "User"}}
                                        inputID={"user"}
                                        selectableOptions={users}
                                        labelName="User"
                                        onChangeCB={(value) => {
                                            handleUserSelection(value);
                                        }}
                                        variant="outlined"
                                    />}
                            </div>
                            <div className="col-4">
                                {accounts &&
                                    <GSPSToolbarSelectField
                                        defaultValue={{key: "DefaultValue", value: 0, display_text: "Account"}}
                                        inputID={"account"}
                                        selectableOptions={accounts}
                                        labelName="Account"
                                        onChangeCB={(value) => {
                                            handleAccountSelection(value);
                                        }}
                                        variant="outlined"
                                    />}
                            </div>
                            <div className="col-4">
                                {locations &&
                                    <GSPSToolbarSelectField
                                        defaultValue={{key: "DefaultValue", value: 0, display_text: "Location"}}
                                        inputID={"location"}
                                        selectableOptions={locations}
                                        labelName="Location"
                                        onChangeCB={(value) => {
                                            handleLocationSelection(value);
                                        }}
                                        variant="outlined"
                                    />}
                            </div>
                            <div className="col-4 my-3 d-flex align-items-center">
                                <DatePickerContainer>
                                    <DatePicker
                                        label="Start Date"
                                        startDate={new Date("2024-01-01")}
                                        minDate={new Date("2024-01-01")}
                                        disableFuture
                                        slotProps={{
                                            textField: {
                                                helperText: 'MM/DD/YYYY',
                                            },
                                        }}
                                        onChange={(date) => setFromDate(date)}
                                        InputLabelProps={{
                                            className: 'label-md',
                                        }}
                                    />
                                </DatePickerContainer>
                            </div>

                            <div className="col-4 my-3">
                                <DatePickerContainer>
                                    <DatePicker
                                        label="End Date"
                                        startDate={new Date("2024-01-01")}
                                        disableFuture
                                        minDate={fromDate}
                                        disabled={!fromDate}
                                        slotProps={{
                                            textField: {
                                                helperText: 'MM/DD/YYYY',
                                            },
                                        }}
                                        onChange={(date) => setToDate(date)}
                                        InputLabelProps={{
                                            className: 'label-md',
                                        }}
                                    />
                                </DatePickerContainer>
                            </div>
                            <div className="col-4 d-flex justify-content-end align-items-center">
                                <GSPSSubmitButton
                                    isDisabled={false}
                                    controlSize={2}
                                    offset={0}
                                    buttonText={"Submit"}
                                    colClassName="w-25 mb-3"
                                />
                            </div>
                        </div>
                    </ToolbarContainer>
                </Form>
                {
                    combinedHistory.length > 0 && <GSPSPropDrivenTable
                        title="History"
                        data={combinedHistory}
                        columns={historyColumns}
                        getRowId={(row) => row.uniqueId}
                        loading={tableLoading}
                        disableColumnMenu
                        pagination
                    />
                }

                <Backdrop
                    sx={{color: '#fff', zIndex: "555"}}
                    open={tableLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </div>
    );
};
