import {Avatar} from "@mui/material"
import PropTypes from "prop-types";
import {AvatarContainer} from "./GSPSProfileAvatar.styles";
import {useTheme} from "styled-components";



const GSPSProfileAvatar = ({width, height}) => {
    const theme = useTheme();
    return <AvatarContainer>
        <Avatar sx={{width: width, height: height, bgcolor: theme.color.info, color: theme.color.dark}} />
    </AvatarContainer>
}

GSPSProfileAvatar.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

export default GSPSProfileAvatar