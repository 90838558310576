import {API_USERS} from "Services/Endpoints";
import {createGenericApi} from "./createGenericApi";

export const userApi = createGenericApi({
    reducerPath: "userApi",
    baseUrl: API_USERS.users,
    tagTypes: ["User"],
});

export const {
    useFetchItemsQuery: useFetchUsersQuery,
    useAddItemMutation: useAddUserMutation,
    useUpdateItemMutation: useUpdateUserMutation,
    useDeleteItemMutation: useDeleteUserMutation,
} = userApi;
