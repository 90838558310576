import {API_ACCOUNTS} from "Models/Account/AccountEndpoints";
import {createGenericApi} from "./createGenericApi";

export const accountApi = createGenericApi({
    reducerPath: "accountApi",
    baseUrl: API_ACCOUNTS.accounts,
    tagTypes: ["Account"], // Use tagTypes
});

export const {
    useFetchItemsQuery: useFetchAccountsQuery,
    useAddItemMutation: useAddAccountMutation,
    useUpdateItemMutation: useUpdateAccountMutation,
    useDeleteItemMutation: useDeleteAccountMutation,
} = accountApi;
