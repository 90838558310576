import React from "react";
import {GSPSError} from "./GSPSErrorMessage.styles";
import PropTypes from "prop-types";

export const GSPSErrorMessage = ({
    messageText,
}) => {
    return <>
        <GSPSError>{messageText}</GSPSError>
    </>;
};


GSPSErrorMessage.propTypes = {
    messageText: PropTypes.string.isRequired,
};
