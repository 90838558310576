import React from "react";
import {Col, Form} from "react-bootstrap";
import PropTypes from "prop-types";
import {useState, useEffect} from "react";
export const GSPSTextArea = ({
    id,
    labelName,
    register,
    errors,
    placeHolder,
    value,
    isInvalid,
    patternType,
    validate,
    isRequired = false,
    isDisabled = false,
    rows = 3,
    setValue = null,
    charLimit = null,
    setDescription,
    autoSave = false,
    updateOnChange,
    hiddenLabel = false,
    hiddenCharLimit = false,
}) => {
    const [textAreaValue, setTextAreaValue] = useState("");

    useEffect(() => {
        if (value === null || value === undefined) {
            setTextAreaValue("");
        } else {
            setValue(id, value);
            setTextAreaValue(value);
        }
    }, [value]);

    function getErrorMessage() {
        if (!errors) {
            return;
        }
        const [baseID, secondaryID] = id.split(".");
        const baseErrorDict = errors?.[baseID];
        if (baseErrorDict && secondaryID) {
            return baseErrorDict[secondaryID]?.message;
        }
        return baseErrorDict?.message;
    }

    return <>
        <Form.Group as={Col} controlId={id}>
            {
                !hiddenLabel &&
                <Form.Label className="fw-bold pb-0">
                    {labelName}: {isRequired && <span className="text-danger">*</span>}
                </Form.Label>
            }
            <Form.Control
                as="textarea"
                placeholder={placeHolder}
                value={textAreaValue}
                disabled={isDisabled}
                isInvalid={isInvalid || Boolean(getErrorMessage())}
                rows={rows}
                maxLength={charLimit || undefined}
                {...register(
                    id,
                    {
                        required: isRequired && "This field is required",
                        pattern: patternType,
                        validate: validate,
                        onChange: (e) => {
                            setTextAreaValue(e.target.value);
                            if (autoSave) {
                                updateOnChange(e.target.value);
                                setDescription(e.target.value);
                            }
                        },
                    },
                )}
            />
            <Form.Control.Feedback type="invalid">
                {getErrorMessage()}
            </Form.Control.Feedback>
            {charLimit && !hiddenCharLimit && (
                <div className="text-muted">
                    {textAreaValue.length}/{charLimit} characters
                </div>
            )}
        </Form.Group>
    </>;
};
GSPSTextArea.propTypes = {
    id: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired,
    controlType: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    patternType: PropTypes.object,
    placeHolder: PropTypes.string,
    value: PropTypes.string,
    isInvalid: PropTypes.object,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    autoSave: PropTypes.bool,
    validate: PropTypes.func,
    setDescription: PropTypes.func,
    updateOnChange: PropTypes.func,
    rows: PropTypes.number,
    setValue: PropTypes.func,
    charLimit: PropTypes.number,
    hiddenLabel: PropTypes.bool,
    hiddenCharLimit: PropTypes.bool,
};
