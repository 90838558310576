import {API_LOCATIONS} from "Models/Location/LocationEndpoints";
import {createGenericApi} from "./createGenericApi";

export const locationApi = createGenericApi({
    reducerPath: "locationApi",
    baseUrl: API_LOCATIONS.locations,
    tagTypes: ["Location"], // Use tagTypes
});

export const {
    useFetchItemsQuery: useFetchLocationsQuery,
    useAddItemMutation: useAddLocationMutation,
    useUpdateItemMutation: useUpdateLocationMutation,
    useDeleteItemMutation: useDeleteLocationMutation,
} = locationApi;
