import React, {useEffect, useState} from "react";
import {createSelectionOptions} from "Services/ServicesUtils";
import PropTypes from "prop-types";
import {GSPSToolbarSelectField} from "Components/Common/GSPSToolbarSelectField";
import {ToolbarContainer} from "./AuditMapToolbar.styles";
import {Button} from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import {useSelector} from "react-redux";

const AuditDeletionOptionsList = [
    {key: "deleteIssues", value: 1, display_text: "Delete Items"},
    {key: "deleteLines", value: 2, display_text: "Delete Lines"},
    {key: "deleteAreas", value: 3, display_text: "Delete Areas"},
];

export const AuditMapToolbar = ({
    AnnotationShapeIDMap,
    onSelectAnnotationShape,
    onAuditTypeChange,
    onAuditTypeItemChange,
    currentAnnotationShape,
    handleDeleteSelection,
    onResetAuditTypeItem,
    MapClicked,
    onDropDownReset,
    handleFilterSelection,
    AuditFilterOptionsList,
    updatedAuditFilter,
    resetDeleteDropdown,
}) => {
    const [auditTypes, setAuditTypes] = useState([]);
    const [currentAuditType, setCurrentAuditType] = useState(null);
    const [auditTypeItems, setAuditTypeItems] = useState([]);
    const [currentAuditTypeItem, setCurrentAuditTypeItem] = useState(null);
    const {auditsTypesItems, auditTypes: allTypes} = useSelector((state)=> state.auditData);
    useEffect(() => {
        createSelectionOptions(allTypes, setAuditTypes);
    }, [allTypes]);

    useEffect(() => {
        if (currentAuditType && currentAnnotationShape?.query) {
            filterItems(currentAnnotationShape.query, currentAuditType.value);
        }
    }, [currentAuditType, currentAnnotationShape?.query]);

    useEffect(() => {
        if (currentAuditType) {
            onAuditTypeChange(currentAuditType);
        }
    }, [currentAuditType, onAuditTypeChange]);

    useEffect(() => {
        if (currentAuditTypeItem) {
            onAuditTypeItemChange(currentAuditTypeItem);
        }
    }, [currentAuditTypeItem, onAuditTypeItemChange]);

    useEffect(() => {
        if (MapClicked) {
            setCurrentAuditTypeItem(null);
            setAuditTypeItems([]);
            onResetAuditTypeItem();
            onDropDownReset();
        }
    }, [MapClicked]);

    const filterItems = (annotationType, auditTypeId) => {
        const type = annotationType === "item" ? "issue" : annotationType;
        const filtered = auditsTypesItems.filter((item) =>
            item.annotation_type === type && item.audit_type.id === auditTypeId).map((item) => ({
            key: item.id,
            value: item.id,
            display_text: item.name,
        }));
        setAuditTypeItems(filtered);
    };

    return (
        <ToolbarContainer>
            <div className="row py-3 align-items-center">
                <div className="col-lg-2 col-12 mb-4 mb-lg-0">
                    <h1 className="subtitle-md my-0">
                        Let's Go!
                    </h1>
                </div>

                <div className="col-lg-6 col-12">
                    <div className="inputsGroup px-3 d-flex justify-content-between align-items-center flex-lg-row flex-column me-lg-2 me-0 mb-2">
                        <div className="w-100 d-flex justify-content-between align-items-center mb-4 mb-lg-0 me-lg-3">
                            {auditTypes &&
                                <GSPSToolbarSelectField
                                    defaultValue={{key: "DefaultValue", value: 0, display_text: "Select"}}
                                    inputID={"audit_type"}
                                    selectableOptions={auditTypes}
                                    onChangeCB={(selectedOption) => {
                                        if (selectedOption && selectedOption.display_text !== "Select") {
                                            setCurrentAuditType(selectedOption);
                                        }
                                    }}
                                    labelName="1- Audit Type"
                                />}
                        </div>
                        <div className="w-100  d-flex justify-content-between align-items-center mb-4 mb-lg-0 me-lg-3">
                            <GSPSToolbarSelectField
                                inputID={"annotation_shape"}
                                selectableOptions={
                                    Object.values(AnnotationShapeIDMap)?.map((obj) => ({
                                        key: obj.id,
                                        value: obj.id,
                                        display_text: obj.query,
                                    }))
                                }
                                DropDownReset={MapClicked}
                                onChangeCB={(selectedItem) => {
                                    if (selectedItem) {
                                        onSelectAnnotationShape(Number(selectedItem.value));
                                    }
                                }}
                                defaultValue={{key: "DefaultValue", value: 0, display_text: "Select"}}
                                labelName={"2- Item Type"}
                            />
                        </div>
                        <div className="w-100 d-flex justify-content-between align-items-center mb-4 mb-lg-0">{auditTypeItems &&
                            <GSPSToolbarSelectField
                                inputID={"audit_type_item"}
                                selectableOptions={auditTypeItems}
                                DropDownReset={MapClicked}
                                onChangeCB={(selectedItem) => {
                                    if (selectedItem && selectedItem.display_text !== "Select") {
                                        setCurrentAuditTypeItem(selectedItem);
                                    } else {
                                        setCurrentAuditTypeItem(null);
                                        onResetAuditTypeItem();
                                    }
                                }}
                                defaultValue={{key: "DefaultValue", value: 0, display_text: "Select"}}
                                disableAutoSorting
                                labelName="3- Audit Item"
                            />
                        }
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-12">
                    <div className="inputsGroup px-3 d-flex justify-content-between align-items-center flex-lg-row flex-column mb-2">
                        <div className="w-100 d-flex justify-content-between align-items-center mb-4 mb-lg-0">
                            <GSPSToolbarSelectField
                                inputID="filter_audits"
                                selectableOptions={AuditFilterOptionsList}
                                onChangeCB={(value) => {
                                    handleFilterSelection(value);
                                }}
                                labelName="View"
                                renderedElementType={"filter"}
                                updatedAuditFilter={updatedAuditFilter}
                            />
                        </div>
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <GSPSToolbarSelectField
                                inputID="delete_actions"
                                selectableOptions={AuditDeletionOptionsList}
                                isRequired={true}
                                onChangeCB={(value) =>
                                    handleDeleteSelection(Object.values(AuditDeletionOptionsList).filter((obj) => obj.value == value)[0].key)
                                }
                                renderedElementType={"select"}
                                labelName="Delete"
                                DropDownReset={resetDeleteDropdown}
                            />
                            <Button type={"button"} variant={"contained"} className="px-3 d-none" startIcon={<CheckBoxOutlinedIcon />} onClick={(e) => {
                                e.preventDefault();
                            }}>
                                Finish
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
        </ToolbarContainer>
    );
};

AuditMapToolbar.propTypes = {
    AnnotationShapeIDMap: PropTypes.object.isRequired,
    onSelectAnnotationShape: PropTypes.func.isRequired,
    currentAnnotationShape: PropTypes.object,
    onAuditTypeChange: PropTypes.func.isRequired,
    onAuditTypeItemChange: PropTypes.func.isRequired,
    onResetAuditTypeItem: PropTypes.func.isRequired,
    handleDeleteSelection: PropTypes.func,
    MapClicked: PropTypes.bool,
    resetDeleteDropdown: PropTypes.bool,
    onDropDownReset: PropTypes.func,
    handleFilterSelection: PropTypes.func,
    AuditFilterOptionsList: PropTypes.array,
    updatedAuditFilter: PropTypes.object.isRequired,
};
