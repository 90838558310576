import React, {useContext, useState} from "react";
import {GSPSSubmitButtonStyle} from "Components/Common/GSPSSubmitButton.styles";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {GSPSDataDrivenTable} from "Components/DataTable/GSPSDataDrivenTable";
import {useFetchAccountsQuery} from "api/AccountTableAPI";
import {AddAccount} from "./AddAccount";
import {Account} from "./Account";
import {NavLink} from "react-router-dom";
import {UserContext} from "context/UserContext";

export const Accounts = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [accountID, setAccountID] = useState(null);
    const {user, permissions} = useContext(UserContext);
    const isAdmin = permissions?.isAdmin;

    const accountColumns = {
        "Account": "name",
        "External Account Contact": "external_account_contact",
        "Account Type": "account_type.name",
        "Account Managers": "manager",
        "Status": "is_active",
    };

    const dataParser = {
        name: (account) => <NavLink
            onClick={() => {
                setAccountID(account.id);
                setShowEditModal(true);
            }} value={account.id}>{account.formattedValue}
        </NavLink>,
    };

    return (
        <div className="accounts">
            <GSPSBreadCrumbs />
            <div className="container">
                {user &&
                    <GSPSDataDrivenTable
                        title="Accounts"
                        columns={accountColumns}
                        dataParser={dataParser}
                        tableAdminBtn={
                            isAdmin && <GSPSSubmitButtonStyle
                                onClick={() => setShowAddModal(true)}
                                className="cta-btn"
                            >
                                Add new account
                            </GSPSSubmitButtonStyle>
                        }
                        useQuery={useFetchAccountsQuery}
                    />
                }
            </div>
            {showAddModal && (
                <AddAccount
                    show={showAddModal}
                    setShow={setShowAddModal}
                />
            )}
            {showEditModal && accountID && (
                <Account
                    show={showEditModal}
                    setShow={setShowEditModal}
                    accountID={accountID}
                />
            )}
        </div>
    );
};
