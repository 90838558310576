export function convertToEst(dateString, useShortFormat = false) {
	if (dateString) {
		const date = new Date(dateString);
		if (useShortFormat) {
			const options = {
				timeZone: "America/New_York",
				year: "numeric",
				month: "short",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: true,
			};
			return new Intl.DateTimeFormat("en-US", options).format(date);
		} else {
			return date.toLocaleString("en-US", { timeZone: "America/New_York" });
		}
	} else {
		return "";
	}
}
