import styled from "styled-components";
import {Row,Container} from "react-bootstrap";


export const SubscriberStyledContainer = styled(Container)`
    top: 12px;
    position: relative;
    align-items: center;    
`;

export const SubscriberBodyContainer = styled(Container)`
     border: 1px solid #E8E7F5;
     padding: 20px;
`;

export const SubscriberStyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: 1px solid #E8E7F5;
`;

export const BtnsContainer = styled.div`
    display: flex;
    gap: 4px;
`;

export const SubscriberTitle = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.25px;
    margin-bottom: 0 !important;
`;

export const StyledSubscriberRow = styled(Row)`
    display: flex;
    gap: 50px;
`;
