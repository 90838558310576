import React from "react";
import * as icons from "react-bootstrap-icons";
import PropTypes from "prop-types";

export const GSPSIcon = ({name, ...props}) => {
    const BootstrapIcon = icons[name];
    return <BootstrapIcon {...props} />;
};

GSPSIcon.propTypes = {
    name: PropTypes.string.isRequired,
};
