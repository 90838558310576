import {AddressForm} from "Components/Common/AddressForm";
import {GSPSLabelledInput} from "Components/Common/GSPSLabelledInput";
import {GSPSLabelledSelectField} from "Components/Common/GSPSLabelledSelectField";
import {API_LOCATIONS} from "Models/Location/LocationEndpoints";
import {useEffect, useState} from "react";
import {createSelectionOptions, makeGetRequest} from "Services/ServicesUtils";
import {insertNotification} from "Utils/NotificationsUtils";
import {Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {Button, CircularProgress} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import PropTypes from "prop-types";
import {useAddAuditMutation, useUpdateAuditMutation} from "api/AuditTableAPI";
import {useSelector} from "react-redux";


const AuditGeneralInfo = ({audit, setAudit, setLoading, setCurrentLocation, location, setLocation}) => {
    const {handleSubmit, register, getValues, reset, setValue, formState: {errors}} = useForm();
    const navigate = useNavigate();
    const [auditTypes, setAuditTypes] = useState([]);
    const [currentAuditType, setCurrentAuditType] = useState();
    const [searchParams] = useSearchParams();
    const locationId = searchParams.get("locationId");
    const [statusTypes, setStatusTypes] = useState();
    const [accounts, setAccounts] = useState();
    const auditId = searchParams.get("auditId");
    const {auditTypes: allTypes, statusTypes: allStatusTypes, accountsDropdown: allAccountsDropdown} = useSelector((state)=> state.auditData);

    const getAddress = async (lat, lng) => {
        const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                insertNotification("Error", "Could not fetch current location.", "error");
            } else {
                const data = await response.json();
                const place = data.results[0];
                let apartmentNumber = "";
                let streetNumber = "";
                let streetName = "";
                let zipCode = "";
                let city = "";
                let state = "";
                let country = "";
                for (let i = 0; i < place.address_components.length; i++) {
                    const component = place.address_components[i];
                    const types = component.types;

                    if (types.includes("street_number")) {
                        streetNumber = component.long_name;
                    } else if (types.includes("route")) {
                        streetName = component.long_name;
                    } else if (types.includes("postal_code")) {
                        zipCode = component.long_name;
                    } else if (types.includes("locality") || types.includes("administrative_area_level_2")) {
                        city = component.long_name;
                    } else if (types.includes("administrative_area_level_1")) {
                        state = component.long_name;
                    } else if (types.includes("country")) {
                        country = component.short_name;
                    } else if (types.includes("subpremise")) {
                        apartmentNumber = component.long_name;
                    }
                }
                const address = {
                    "full_address": place.formatted_address,
                    "latitude": place.geometry.location.lat,
                    "longitude": place.geometry.location.lng,
                    "place_api_id": place.place_id,
                    "apt_num": apartmentNumber,
                    "street_num": streetNumber,
                    "street_name": streetName,
                    "city": city,
                    "state": state,
                    "zip": zipCode,
                };
                return address;
            }
        } catch (e) {
            insertNotification("Error", "Something went wrong while fetching current location.", "error");
        }
    };

    useEffect(() => {
        if (!(locationId || auditId)) {
            const errorCallback = (error) => {
                setLocation(true); // To load the form for user to enter manually
                insertNotification("Error", "Unable to detect your location. Enable location permission for the browser.", "error");
            };
            const successCallback = async (position) => {
                const currentPos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                const address = await getAddress(currentPos.lat, currentPos.lng);
                setLocation({
                    ...location,
                    address: address,
                });
                setCurrentLocation(currentPos);
            };
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {maximumAge: 0, timeout: 5000, enableHighAccuracy: true});
        }
        locationId && makeGetRequest(`${API_LOCATIONS.locations}${locationId}/`).then(
            (res) => {
                const updateCurrentPos = {
                    lat: parseFloat(res?.address?.latitude),
                    lng: parseFloat(res?.address?.longitude),
                };
                setCurrentLocation(updateCurrentPos);
                setLocation(res);
                reset(res);
            });
    }, []);
    useEffect(() => {
        createSelectionOptions(allTypes, setAuditTypes);
        createSelectionOptions(allAccountsDropdown, setAccounts);
        createSelectionOptions(allStatusTypes, setStatusTypes);
    }, [allTypes]);
    useEffect(() => {
        setCurrentAuditType(auditTypes[0]);
    }, [auditTypes]);
    const [addAudit] = useAddAuditMutation();
    const [updateAudit] = useUpdateAuditMutation();
    const onSubmit = async (values) => {
        try {
            const newAudit = populateAudit(values);
            if (!auditId) {
                const jsonRes = await addAudit(newAudit).unwrap();
                setAudit(jsonRes);
                insertNotification("Success", `Audit ${jsonRes.id} has been created.`, "success");
                navigate(`/audits/creator?auditId=${jsonRes.id}`);
            } else {
                const updatedAudit = {...newAudit};
                const jsonRes = await updateAudit({id: audit.id, updatedItem: updatedAudit}).unwrap();
                const updateCurrentPos = {
                    lat: parseFloat(jsonRes?.location?.address?.latitude),
                    lng: parseFloat(jsonRes?.location?.address?.longitude),
                };
                setCurrentLocation(updateCurrentPos);
                setAudit(jsonRes);
                insertNotification("Success", `Audit ${jsonRes.id} has been updated.`, "success");
            }
        } catch (e) {
            insertNotification("Error", `Can't create or update audit: ${e}`, "error");
        }
    };

    const populateAudit = (values) => {
        const location_lnglat = {
            "lat": values.address.latitude ?? audit?.location?.address?.latitude,
            "lng": values.address.longitude ?? audit?.location?.address?.longitude,
        };
        return {
            name: values["audit_name"] ?? audit?.name,
            audit_status_id: 1,
            audit_type_id: currentAuditType.value,
            address_lnglat: location_lnglat,
            location: {
                id: locationId,
                account_id: values?.location_account_id ?? audit?.location?.account?.id,
                project_id: values.project_id ?? audit?.project_id,
                location: values.location ?? audit?.name,
                status_type: values.status_type ?? audit?.status_type,
                address: {
                    latitude: location_lnglat.lat,
                    longitude: location_lnglat.lng,
                    place_api_id: values.address.place_api_id ?? audit?.location?.address?.place_api_id,
                    apt_num: values.address.apt_num ?? audit?.location?.address?.apt_num,
                    city: values.address.city ?? audit?.location?.address?.city,
                    state: values.address.state ?? audit?.location?.address?.state,
                    street_name: values.address.street_name ?? audit?.location?.address?.street_name,
                    street_num: values.address.street_num ?? audit?.location?.address?.street_num,
                    zip: values.address.zip ?? audit?.location?.address?.zip,
                },
            },
        };
    };

    useEffect(() => {
        if (location && accounts && statusTypes) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [location, accounts, statusTypes]);

    return (
        <div className="AuditGeneralInfo mb-5">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className="subtitle-md mb-3">General Information</h1>
                    <Button className="cta-btn" type="submit" variant={"contained"} startIcon={<SaveIcon />}>
                        Save
                    </Button>
                </div>
                {
                    location && accounts && statusTypes ?
                        <>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12 mb-2">
                                    <GSPSLabelledInput
                                        value={audit?.name}
                                        labelName={"Audit Name"}
                                        id={"audit_name"}
                                        controlType={"input"}
                                        isRequired={true}
                                        register={register}
                                        errors={errors}
                                        placeHolder="Enter Value"
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-2">
                                    <GSPSLabelledInput
                                        id="location"
                                        controlType={"input"}
                                        value={location?.location}
                                        labelName={"Description"}
                                        register={register}
                                        errors={errors}
                                        placeHolder="Enter Value"
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 mb-2">
                                    <AddressForm
                                        id="address"
                                        caller={location}
                                        register={register}
                                        errors={errors}
                                        setValue={setValue}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-2">
                                    <GSPSLabelledSelectField
                                        labelName={"Account"}
                                        inputID={"location_account_id"}
                                        selectableOptions={accounts}
                                        defaultValue={location?.account?.id}
                                        isRequired={true}
                                        register={register}
                                        errors={errors}
                                        placeHolder="Enter Value"
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 mb-2">
                                    <GSPSLabelledSelectField
                                        labelName={"Property Type"}
                                        inputID={"status_type"}
                                        defaultValue={location?.status_type}
                                        selectableOptions={statusTypes}
                                        register={register}
                                        errors={errors}
                                        placeHolder="Enter Value"
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 mb-2">
                                    <GSPSLabelledInput
                                        id="project_id"
                                        controlType={"input"}
                                        value={location?.project_id}
                                        labelName={"Project ID"}
                                        register={register}
                                        errors={errors}
                                        placeHolder="Enter Value"
                                    />
                                </div>
                            </div>
                        </> :
                        <CircularProgress size={20} color="inherit" />
                }
            </Form>
        </div>
    );
};

AuditGeneralInfo.propTypes = {
    setLoading: PropTypes.func.isRequired,
    audit: PropTypes.object.isRequired,
    setCurrentLocation: PropTypes.func,
};

export default AuditGeneralInfo;
