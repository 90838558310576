import React from "react";
import PropTypes from "prop-types";
import {GSPSContainer, RequiredFormFieldIcon} from "Components/Common/GSPSLabeledInput.styles";
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import {GSPSFormControlStyled} from 'Components/Common/GSPSLabelledSelectFieldWithIcon.styles'
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import {FormControl, FormHelperText} from "@mui/material";
import {useIsDesktop} from "Services/ServicesUtils";

export const GSPSLabelledSelectFieldWithIcon = ({
    inputID,
    register = () => { },
    selectableOptions,
    labelName,
    isRequired = false,
    defaultValue = null,
    errors,
    isInvalid,
    validate,
    isDisabled = false,
}) => {
    const isDesktop = useIsDesktop();
    const isIpad = /iPad|Macintosh/i.test(navigator.userAgent) && 'ontouchend' in document;
    const getErrorMessage = () => {
        return errors && errors[inputID] ? errors[inputID].message : "";
    };

    return (
        <GSPSContainer>
            <GSPSFormControlStyled>
                <label column htmlFor={inputID} className="pb-0 label-sm">
                    {labelName}: {isRequired && <RequiredFormFieldIcon className="--bs-danger">* </RequiredFormFieldIcon>}
                </label>
                <FormControl focused fullWidth>
                    <Select
                        id={inputID}
                        name={inputID}
                        defaultValue={defaultValue !== null ? defaultValue : ""}
                        disabled={isDisabled}
                        isInvalid={isInvalid || !!getErrorMessage()}
                        {...register(
                            inputID,
                            {
                                required: isRequired && {value: true, message: "This field is required."},
                                validate: validate,
                            }
                        )}
                    >
                        {(selectableOptions || []).map((optionObject) => {
                            const colorVal = optionObject.color_code;
                            return (
                                <MenuItem
                                    value={optionObject.id}
                                    key={optionObject.id}
                                >
                                    {isDesktop && !isIpad && colorVal && (
                                        <ListItemIcon>
                                            <CircleIcon style={{color: colorVal, scale: "0.8"}} />
                                        </ListItemIcon>
                                    )}
                                    <ListItemText primary={optionObject.name} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText>
                        {errors && errors[inputID] && "This field is required"}
                    </FormHelperText>
                </FormControl>
            </GSPSFormControlStyled>
        </GSPSContainer>
    );
};

GSPSLabelledSelectFieldWithIcon.propTypes = {
    inputID: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired,
    register: PropTypes.func,
    selectableOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        color_code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })),
    isRequired: PropTypes.bool,
    defaultValue: PropTypes.number,
    onChangeCB: PropTypes.func,
    errors: PropTypes.object,
    isInvalid: PropTypes.object,
    validate: PropTypes.func,
    isDisabled: PropTypes.bool,
};
