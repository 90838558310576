import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {GSPSMediaLogoSelector} from "Components/Common/GSPSMediaLogoSelector";
import {StyledRemoveImgLabel} from "./GSPSLabeledInput.styles";

export const GSPSImageSelector = ({
    id,
    controlSize,
    imageContainerWidthPx,
    imageContainerHeightPx,
    setValue,
    currentImageSrc,
    isViewOnly = false,
    handleDeleteImage,
    setHasLogo
}) => {
    const [currentImg, setCurrentImg] = useState(null)
    useEffect(() => {
        setCurrentImg(currentImageSrc)
    }, [currentImageSrc])

    return (
        <div className="position-relative">
            <GSPSMediaLogoSelector
                id={id}
                controlSize={controlSize}
                imageContainerWidthPx={imageContainerWidthPx}
                imageContainerHeightPx={imageContainerHeightPx}
                setValue={setValue}
                currentImageSrc={currentImg}
                isViewOnly={isViewOnly}
                mediaType={"image"}
                handleDeleteImage={handleDeleteImage}
                setHasLogo={setHasLogo}
            />
        </div>
    );
};

GSPSImageSelector.propTypes = {
    id: PropTypes.string.isRequired,
    controlSize: PropTypes.number,
    imageContainerWidthPx: PropTypes.number.isRequired,
    imageContainerHeightPx: PropTypes.number.isRequired,
    setValue: PropTypes.func,
    currentImageSrc: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    isViewOnly: PropTypes.bool,
    handleDeleteImage: PropTypes.func,
    setHasLogo: PropTypes.bool
};
