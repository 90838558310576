import React from "react";
import {useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {LocationForm} from "Components/Locations/LocationForm";
import {insertNotification} from "Utils/NotificationsUtils";
import {GSPSPageWrapper, GSPSPopUpHeader, GSPSPopUpFooter} from "Components/Common/GSPSStyledContainers.styles";
import {useAddLocationMutation} from "api/LocationTableAPI";
import {GSPSCancelButton} from "Components/Common/GSPSCancelButton";
import PropTypes from "prop-types";

export const AddLocation = ({show, setShow}) => {
    const {handleSubmit, register, setValue, formState: {isDirty, touched, errors}} = useForm();
    const [addLocation, {isLoading, isError, isSuccess, error}] = useAddLocationMutation();

    const onSubmit = async (values) => {
        try {
            await addLocation({...values, account_id: values.location_account_id}).unwrap();
            insertNotification("Success", `Location ${values.location} has been Added ..`, "success");
            setShow(false);
        } catch (e) {
            console.error("Something is wrong with saving Location details", e.toString());
            insertNotification("Error", `can't add location: ${e.message}`, "error");
        }
    };

    return (
        <>
            <GSPSPageWrapper className="show" role="dialog" show={show} centered size={"lg"}>
                <GSPSPopUpHeader>
                    Add New Location
                </GSPSPopUpHeader>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <LocationForm
                        register={register}
                        errors={errors}
                        setValue={setValue}
                    />
                    <GSPSPopUpFooter>
                        <GSPSCancelButton
                            controlSize={2}
                            onClick={(event) => {
                                event.preventDefault();
                                setShow(false);
                            }}
                            buttonText={"Cancel"} />

                        <GSPSSubmitButton
                            isDisabled={(!isDirty && touched) || errors.zip?.message.length > 0 || isLoading}
                            isLoading={isLoading}
                            controlSize={2}
                            buttonText={"Save"} />
                    </GSPSPopUpFooter>
                </Form>
            </GSPSPageWrapper>
        </>
    );
};
AddLocation.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
};
