import React, {createContext, useState, useEffect} from "react";
import {UserPermissionGroupIDs} from "Models/User/UserConstants";
import {fetchCurrentUser} from "Models/User/UserUtils";

export const UserContext = createContext();

const UserPermissions = {
    useIsSuperAdmin: (user) => user?.group_permission?.id === UserPermissionGroupIDs.SUPER_ADMIN,
    useIsGroupAdmin: (user) => user?.group_permission?.id === UserPermissionGroupIDs.GROUP_ADMIN,
    useIsAdmin: (user) =>
        [UserPermissionGroupIDs.GROUP_ADMIN, UserPermissionGroupIDs.SUPER_ADMIN].includes(user?.group_permission?.id),

    useIsStaff: (user) => user?.is_staff,

};

export const UserProvider = ({children}) => {
    const [user, setUser] = useState({});
    const [permissions, setPermissions] = useState({
        isSuperAdmin: false,
        isGroupAdmin: false,
        isAdmin: false,
        isStaff: false,
    });

    useEffect(() => {
        fetchCurrentUser(setUser);
    }, []);

    useEffect(() => {
        if (user?.group_permission?.id) {
            setPermissions({
                isSuperAdmin: UserPermissions.useIsSuperAdmin(user),
                isGroupAdmin: UserPermissions.useIsGroupAdmin(user),
                isAdmin: UserPermissions.useIsAdmin(user),
                isStaff: UserPermissions.useIsStaff(user),
            });
        }
    }, [user]);

    return (
        <UserContext.Provider value={{user, permissions}}>
            {children}
        </UserContext.Provider>
    );
};
