import styled from "styled-components";

export const AuditsPage = styled.div`
    & .auditsCreated .MuiSvgIcon-root{
        color: #DD7CC2;
    }
    & .auditsCompleted .MuiSvgIcon-root{
        color: #4CA2A8;
    }
    & .auditsInReview .MuiSvgIcon-root{
        color: #E6B056;
    }
    & .auditsRequestEdits .MuiSvgIcon-root{
        color: #343a40;
    }

    & .MuiTab-root{
        text-transform: none;
    }
    & .MuiTabs-indicator{
        background: ${({theme}) => theme.color.dark} !important;
    }    
`