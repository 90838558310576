import React from 'react';
import PropTypes from 'prop-types';
import {Divider, MenuItem, Typography, ListItemIcon, Menu} from "@mui/material";
import GSPSProfileAvatar from "./GSPSProfileAvatar";
import {useTheme} from "styled-components";

const GSPSAccountNavActions = ({open, handleClose, anchorEl, user, actions}) => {
    const theme = useTheme();

    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    width: "320px",
                    bgcolor: theme.color.bodyBackground,
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: theme.color.bodyBackground,
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
            <MenuItem onClick={handleClose} className="d-flex flex-column justify-content-center align-items-center">
                <GSPSProfileAvatar width={60} height={60} />
                <Typography variant="h1" component="h1" className="mt-3 mb-0"
                    sx={{fontSize: "14px", fontWeight: "500", color: theme.color.dark, textTransform: "capitalize"}}>
                    {user.full_name}
                </Typography>
                <Typography variant="p" component="p" className="mt-1"
                    sx={{fontSize: "12px", fontWeight: "400", color: theme.color.secondaryText}}>
                    {user.email}
                </Typography>
            </MenuItem>
            <Divider />
            {actions.map((action, index) => (
                <React.Fragment key={index}>
                    {action}
                    {index !== actions.length - 1 && <Divider />}
                </React.Fragment>
            ))}
        </Menu>
    );
};

GSPSAccountNavActions.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    user: PropTypes.shape({
        full_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    }).isRequired,
    actions: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default GSPSAccountNavActions;
