import React, {useState, useEffect} from "react";
import {fetchSelectionOptions} from "Services/ServicesUtils";
import {API_ACCOUNTS} from "Models/Account/AccountEndpoints";
import PropTypes from "prop-types";
import {RequiredFormFieldIcon, GSPSContainer} from "Components/Common/GSPSLabeledInput.styles";

export const AccountAuditTypes = ({
    id,
    account,
    labelSize = 5,
    setAuditTypes,
    auditTypes,
}) => {
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        fetchSelectionOptions(API_ACCOUNTS.auditTypes, (options) => {
            const updatedOptions = options.map((option) => ({
                ...option,
                selected: account?.account_audit_types?.some((auditType) => auditType.id === option.key) ??
                    false,
            }));
            setAuditTypes(updatedOptions);
            setSelectAll(updatedOptions.every((option) => option.selected));
        }, "name");
    }, [account]);

    const handleSelectAll = (event) => {
        setSelectAll(event.target.checked);
        setAuditTypes(auditTypes.map((option) => ({
            ...option,
            selected: event.target.checked,
        })));
    };

    const handleCheckboxChange = (key) => {
        setAuditTypes(auditTypes.map((option) => {
            return key === option.key ? {
                ...option,
                selected: !option.selected,
            } : option;
        }));
    };

    return (
        <>
            {auditTypes?.length > 0 &&
                <>
                    <GSPSContainer>
                        <label column sm={labelSize} htmlFor={id} className="fw-bold label-md">
                            {"Audit Types "} <RequiredFormFieldIcon>* </RequiredFormFieldIcon>
                        </label>
                        <div className="col-12">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="select-all"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                                <label className="form-check-label" htmlFor="select-all">
                                    Select All
                                </label>
                            </div>
                        </div>
                        {auditTypes.map((optionObject, index) => (
                            <div className="col-md-10 ms-4" key={optionObject.key}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={optionObject.value}
                                        id={`checkbox-${optionObject.key}`}
                                        checked={optionObject.selected}
                                        onChange={() => handleCheckboxChange(optionObject.key)}
                                    />
                                    <label className="form-check-label" htmlFor={`checkbox-${optionObject.key}`}>
                                        {optionObject.display_text}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </GSPSContainer>
                </>
            }
        </>

    );
};

AccountAuditTypes.propTypes = {
    id: PropTypes.string.isRequired,
    setAuditTypes: PropTypes.func,
    auditTypes: PropTypes.array,
    account: PropTypes.object,
    labelSize: PropTypes.number,
};

