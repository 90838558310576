import {Box, IconButton, Modal} from "@mui/material"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PropTypes from "prop-types";
import {GSPSMediaPreviewContainer} from "./GSPSMediaPreview.styles";



const GSPSMediaPreview = ({setPreviewShow, open, media, mediaType}) => {
    return <div className="MediaPreview">
        <Modal
            open={open}
            onClose={() => setPreviewShow(null)}
        >
            <GSPSMediaPreviewContainer className="px-5 py-2">
                <IconButton
                    className="closeBtn"
                    aria-label="close"
                    size="large"
                    onClick={() => setPreviewShow(null)}
                >
                    <CloseRoundedIcon />
                </IconButton>
                {
                    mediaType === "img" ? <img className="media" src={media} alt="audit media" /> :
                        <video
                            className="media"
                            src={media}
                            controls
                            autoPlay
                        ></video>
                }
            </GSPSMediaPreviewContainer>
        </Modal>
    </div>
}

GSPSMediaPreview.propTypes = {
    setPreviewShow: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    media: PropTypes.bool.isRequired,
    mediaType: PropTypes.oneOf(['img', 'video']).isRequired,
}

export default GSPSMediaPreview