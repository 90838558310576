import {enqueueSnackbar} from "notistack";

export function insertNotification(title, message, type, duration = 3000) {
    enqueueSnackbar({
        variant: type,
        autoHideDuration: duration,
        anchorOrigin: {vertical: "top", horizontal: "center"},
        message: message,
        title: title,
    })
}
