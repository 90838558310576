import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Typography from "@mui/joy/Typography";
import Check from "@mui/icons-material/Check";
import EditRoadIcon from '@mui/icons-material/EditRoad';
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {useState} from "react";
import {makeGetRequest} from "Services/ServicesUtils";
import {API_PLANS} from "Models/Plans/PlansEndpoints";
import {useEffect} from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import {insertNotification} from "Utils/NotificationsUtils";
import {useNavigate} from "react-router-dom";

export function Plans() {
    const [plans, setPlans] = useState();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = () => {
            makeGetRequest(API_PLANS.plans).then(
                (res) => {
                    setPlans(res.results);
                    setLoading(false);
                })
                .catch(
                    (error) => insertNotification("Error", `Something went wrong. Reason: ${error.message}`, "error"),
                );
        };
        fetchData();
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100%",
                bgcolor: "background.default",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "700px",
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(min(100%, 300px), 1fr))",
                    gap: 2,
                }}
            >
                {/* <Card size="lg" variant="outlined">
                    <Chip size="sm" variant="outlined" color="neutral">
          BASIC
                    </Chip>
                    <Typography level="h2">Professional</Typography>
                    <Divider inset="none" />
                    <List size="sm" sx={{mx: "calc(-1 * var(--ListItem-paddingX))"}}>
                        <ListItem>
                            <ListItemDecorator>
                                <Check />
                            </ListItemDecorator>
            Virtual Credit Cards
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>
                                <Check />
                            </ListItemDecorator>
            Financial Analytics
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>
                                <Check />
                            </ListItemDecorator>
            Checking Account
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>
                                <Check />
                            </ListItemDecorator>
            API Integration
                        </ListItem>
                    </List>
                    <Divider inset="none" />
                    <CardActions>
                        <Typography level="title-lg" sx={{mr: "auto"}}>
            3.990€{" "}
                            <Typography fontSize="sm" textColor="text.tertiary">
              / month
                            </Typography>
                        </Typography>
                        <Button
                            variant="soft"
                            color="neutral"
                            endDecorator={<KeyboardArrowRight />}
                        >
            Start now
                        </Button>
                    </CardActions>
                </Card> */}
                {plans && plans.map((plan) =>{
                    const activePrice = plan.prices.filter((price) => price.is_active === true)[0];
                    return <Card
                        key={plan.id}
                        size="lg"
                        variant="solid"
                        color="neutral"
                        invertedColors
                        sx={{bgcolor: "neutral.900"}}
                    >
                        <Chip size="sm" variant="outlined">
          MOST POPULAR
                        </Chip>
                        <Typography level="h2">{plan.name}</Typography>
                        {plan.description && <Typography level="h6">{plan.description}</Typography>}
                        <Divider inset="none" />
                        <List
                            size="sm"
                            sx={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                mx: "calc(-1 * var(--ListItem-paddingX))",
                            }}
                        >
                            <ListItem>
                                <ListItemDecorator>
                                    <EditRoadIcon />
                                </ListItemDecorator>
            {plan?.tier?.max_audits_allowed && `Maximum Audits Allowed: ${plan?.tier?.max_audits_allowed}`}
                            </ListItem>
                        </List>
                        <Divider inset="none" />
                        <CardActions>
                            <Typography level="title-lg" sx={{mr: "auto"}}>
                                {
                                activePrice?.amount + activePrice?.currency}{" "}
                                <Typography fontSize="sm" textColor="text.tertiary">
                                    {activePrice?.billing_period}
                                </Typography>
                            </Typography>
                            <Button endDecorator={<KeyboardArrowRight />} onClick={()=>{
                                navigate("/checkout/" + activePrice?.stripe_lookup_key);
                            }}>Start now</Button>
                        </CardActions>
                    </Card>;
                },
                )}
            </Box>
            <Backdrop
                sx={{color: "#fff", zIndex: "555"}}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
