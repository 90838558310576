import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {Locations} from "Pages/Location/Locations";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {UserContext} from "context/UserContext";

export const LocationsManager = () => {
    const navigate = useNavigate();
    const {user, permissions} = useContext(UserContext);
    const isAdmin = permissions.isAdmin;

    const routeChange = () =>{
        const path = "/locations/add";
        navigate(path);
    };
    return (
        <>
            <h2> Manage Locations </h2>
            {isAdmin &&
                <GSPSSubmitButton
                    controlSize={5}
                    buttonText={"Add new location"}
                    offset={5}
                    onClick={() => routeChange()}
                />
            }
            {user && user?.subscriber &&
                <Locations />
            }

        </>
    );
};
