import React, {useState, useEffect} from "react";
import {makeGetRequest, makePostRequest} from "Services/ServicesUtils";
import {Navigate} from "react-router-dom";
import {API_PAYMENT} from "Models/Checkout/CheckoutEndpoints";
import {API_SUBSCRIPTIONS} from "Models/Subscription/SubscriptionEndpoints";
export const Return = () => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState("");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");
    useEffect(() => {
        makeGetRequest(`${API_PAYMENT.sessionStatus}?session_id=${sessionId}`)
            .then((data) => {
                setStatus(data.status);
                setCustomerEmail(data.customer_email);
            });
    }, []);
    useEffect(() => {
        if (status === "complete") {
            makePostRequest(API_SUBSCRIPTIONS.subscriptions, {
                "session_id": sessionId,
            });
        }
    }, [status, sessionId]);

    if (status === "open") {
        return (
            <Navigate to="/checkout" />
        );
    }

    if (status === "complete") {
        return (
            <section id="success">
                <p>
            We appreciate your business! A confirmation email will be sent to {customerEmail}.

            If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
                </p>
            </section>
        );
    }

    return null;
};
