import {SnackbarContent, useSnackbar} from "notistack";
import React from "react";
import successIcon from "./success_img.png"
import errorIcon from "./error_img.png"
import {NotificationContainer} from "./GSPSNotification.styles";
import {CloseSharp} from "@mui/icons-material";
import {IconButton} from "@mui/material";

const GSPSNotification = React.forwardRef((props, ref) => {
    const {
        id,
        message,
        title,
        variant,
        ...other
    } = props

    const {closeSnackbar} = useSnackbar()
    return (
        <NotificationContainer>
            <SnackbarContent ref={ref} {...other} className="bg-white w-100 shadow rounded">
                <div className="container py-3 position-relative">
                    <div className="closeIcon position-absolute end-0 top-0">
                        <IconButton onClick={() => closeSnackbar(id)}>
                            <CloseSharp fontSize="small" />
                        </IconButton>
                    </div>
                    <div className="row">
                        <div className="col-3 d-flex justify-content-center align-items-center">
                            <img src={variant === "success" ? successIcon : errorIcon} alt={`${variant} icon`} className="img-fluid" />
                        </div>
                        <div className="col ps-0 d-flex flex-column justify-content-center align-items-start">
                            <h1 className={`title ${variant} m-0 mb-2 py-0`}>
                                {title}
                            </h1>
                            <p className="message m-0">
                                {message}
                            </p>
                        </div>
                    </div>
                </div>
            </SnackbarContent>
        </NotificationContainer>
    );
});

export default GSPSNotification;