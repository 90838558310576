import styled from "styled-components";

export const FooterContainer = styled.div`
    padding-top: 130px;
    footer{
        border-top: 1px solid ${({theme}) => theme.color.border};
        background: ${({theme}) => theme.color.secondaryBg};
    }
    .copyRights, li a{
        font-size: 12px;
        font-weight: 400;
        color: ${({theme}) => theme.color.secondaryText};
        text-decoration: none;
        transition: all 0.5s ease-in-out;
    }
    li:hover a{
        color: ${({theme}) => theme.color.info}
    }
`