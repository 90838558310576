import HomeIcon from '@mui/icons-material/Home';
import {Breadcrumbs, SvgIcon} from "@mui/material";
import {ActiveCrumb, GSPSBreadCrumbsContainer, HomeCrumb, LinkContainer} from './GSPSBreadCrumbs.styles';
import {Link, useLocation} from 'react-router-dom';
import PropTypes from "prop-types";


const GSPSBreadCrumbs = ({staticCrumb}) => {
    const location = useLocation();
    const crumbs = location.pathname.split("/").filter(Boolean);

    return <GSPSBreadCrumbsContainer className='py-2 mb-4'>
        <div className="container">
            <Breadcrumbs aria-label="breadcrumb" >
                <Link to={"/"}>
                    <HomeCrumb>
                        <HomeIcon />
                    </HomeCrumb>
                </Link>
                {
                    staticCrumb ? <ActiveCrumb className='subtitle-md'>{staticCrumb}</ActiveCrumb> : crumbs && crumbs.map((crumb, index) => {
                        const lastCrumb = index + 1 !== crumbs.length
                        if (lastCrumb) {
                            return <Link key={index} to={`/${crumb}`}>
                                <LinkContainer>
                                    {crumb}
                                </LinkContainer>
                            </Link>
                        } else {
                            return <ActiveCrumb key={index} className='subtitle-md'>{crumb}</ActiveCrumb>
                        }
                    })
                }
            </Breadcrumbs>
        </div>
    </GSPSBreadCrumbsContainer>
}

GSPSBreadCrumbs.propTypes = {
    staticCrumb: PropTypes.string
}

export default GSPSBreadCrumbs