import {Button} from "react-bootstrap";
import styled from "styled-components";

export const GSPSSubmitButtonStyle = styled(Button)`
    border-radius: 0.25rem;
    border-color: ${({theme}) => theme.color.border};
    background: ${({theme}) => theme.color.primary};
    display: inline-flex;
    padding: 0.75rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    color: ${({theme}) => theme.color.dark};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    min-width: 6rem;
    white-space: nowrap;
    margin-left: 0;
    width: inherit;
    height: 44px;

    &:hover {
        background: ${({ theme }) => theme.color.primaryHover};
        border-color:  ${({ theme }) => theme.color.primaryHover};
    }
    &:active {
        background: ${({ theme }) => theme.color.primary} !important;
        border-color:  ${({ theme }) => theme.color.primary} !important;
    }
`;