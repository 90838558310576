import React from "react";


const PageNotFound = () => {
    return (
        <div className="App-header">
            {"Not Found"}
        </div>
    );
};

export {PageNotFound};
