import React from "react";
import {Modal, Button} from "react-bootstrap";
import PropTypes from "prop-types";

const ConfirmationModal = ({show, onHide, onConfirm, action, message}) => {
    const getActionMessage = (action) => {
        switch (action) {
            case "deleteIssues":
                return "Are you sure you want to delete items?";
            case "deleteLines":
                return "Are you sure you want to delete lines?";
            case "deleteAreas":
                return "Are you sure you want to delete areas?";
            default:
                return message;
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>{getActionMessage(action)}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={onConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
ConfirmationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    action: PropTypes.string,
    message: PropTypes.string,
};
