import {getCorePrefix} from "Services/Endpoints";

const prefix = getCorePrefix();

export const API_SUBSCRIBER = {
    subscribers: `${prefix}subscribers/`,
    subscriberLogo: (subscriberId) => {
        return `${prefix}subscribers/${subscriberId}/logo/`;
    },
    defaultUnits: `${prefix}subscribers/default/units/`,
    industries: `${prefix}subscribers/industries/`,
};
