import {getCorePrefix} from "Services/Endpoints";

const prefix = getCorePrefix();

export const API_ACCOUNTS = {
    accounts: `${prefix}accounts/`,
    accountsDropdown: `${prefix}accounts-dropdown/`,
    accountTypes: `${prefix}accounts/types`,
    accountLogo: (accountId) => {
        return `${prefix}accounts/${accountId}/logo/`;
    },
    auditTypes: `${prefix}accounts/audit_types`,
    accountsWithItems: `${prefix}accounts/items/`,
};
