import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Col, Form} from "react-bootstrap";
import {GSPSContainer, RequiredFormFieldIcon} from "Components/Common/GSPSLabeledInput.styles";

export const GSPSLabelledSelectField = ({
    inputID,
    register = () => { },
    selectableOptions,
    labelName,
    isBold = false,
    isRequired = false,
    defaultValue = null,
    onChangeCB = null,
    errors,
    isInvalid,
    validate,
}) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    useEffect(() => {
        setSelectedValue(defaultValue);
    }, [defaultValue]);

    const getErrorMessage = () => {
        return errors && errors[inputID] ? errors[inputID].message : "";
    };
    const sortedOptions = (selectableOptions || []).sort((a, b) =>
        a.display_text.localeCompare(b.display_text),
    );
    return (
        <GSPSContainer className="my-3">
            <label column htmlFor={inputID} className="pb-0 label-sm">
                {isBold ? <strong>{labelName}</strong> : <strong>{labelName}</strong>}: {isRequired && <RequiredFormFieldIcon className="--bs-danger">* </RequiredFormFieldIcon>}
            </label>
            <Col>
                <Form.Select
                    as="select"
                    id={inputID}
                    name={inputID}
                    defaultValue={selectedValue}
                    onChange={onChangeCB}
                    className="form-select"
                    isInvalid={isInvalid || !!getErrorMessage()}
                    {...register(
                        inputID,
                        {
                            required: isRequired && {value: true, message: "This field is required."},
                            validate: validate,
                        },
                    )}
                >
                    <option value="">Select</option>
                    {sortedOptions.map((optionObject) => (
                        <option key={optionObject.key} value={optionObject.value}>
                            {optionObject.display_text}
                        </option>
                    ))}
                </Form.Select>
                {errors && errors[inputID] && (
                    <Form.Control.Feedback type="invalid">
                        {"This field is required"}
                    </Form.Control.Feedback>
                )}
            </Col>
        </GSPSContainer>
    );
};

GSPSLabelledSelectField.propTypes = {
    inputID: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired,
    isBold: PropTypes.bool,
    register: PropTypes.func,
    selectableOptions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        display_text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })),
    isRequired: PropTypes.bool,
    defaultValue: PropTypes.number,
    onChangeCB: PropTypes.func,
    errors: PropTypes.object,
    isInvalid: PropTypes.object,
    validate: PropTypes.func,
};
