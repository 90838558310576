import styled from "styled-components";

export const NotificationContainer = styled.div`
    min-width: 340px;
    .title{
        font-weight: 600;
        font-size: 14px;
        line-hight: 20px;
        line-height: normal;
        text-transform: capitalize;
    }
    .title.success{
        color: #54967D;
    }
    .title.error{
        color: #C21A2C
    }
    .message{
        font-size: 14px;
        font-weight: 400;
    }
`