import styled from "styled-components";

export const DatePickerContainer = styled.div`
    width: 100%;
    .MuiFormControl-root {
        width: 100%;
    }
        
    & .MuiFormLabel-root.Mui-focused {
        color: ${({theme}) => theme.color.dark} !important;
    }
`