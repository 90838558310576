import {Col} from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";
import {GSPSMediaSelector} from "Components/Common/GSPSMediaSelector";
import {GSPSIcon} from "Icons/GSPSIcon";
import {insertNotification} from "Utils/NotificationsUtils";
import {StyledMediaContainer, StyledMediaInput} from "Pages/Audits/AuditMedia.styles";


export const initMediaSelector = (elementType, elementId, mediaId, mediaValue) => {
    return {elementType: elementType, elementId: elementId, mediaId: mediaId, mediaValue: mediaValue};
};

export const AuditMedia = ({
    rowId,
    rowMedia,
    updateElementMedia,
    elementType,
    limit,
    mediaType = "image",
    isViewOnly = true,
    deleteMedia,
}) => {
    const mediaIndexFromId = (mediaId) => {
        const idx = mediaId.split("_");
        if (idx.length < 4) return -1;
        return parseInt(idx[3]);
    };

    const mediaIdFromIndex = (issueIndex, mediaId) => {
        return `${mediaType}_${elementType}_${issueIndex}_${mediaId}`;
    };


    // Functions
    const handleChangeImage = (event, index) => {
        event.preventDefault();
        const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200MB in bytes
        const file = event.target.files[0];
        if (file.size > MAX_FILE_SIZE) {
            insertNotification("Error", "File size exceeds 200MB limit", "error");
            return;
        }
        try {
            onMediaChange(elementType, index, index, event.target.files);
        } catch (e) {
            insertNotification("Error", "Coudn't upload File", "error");
        }
    };
    function getMediaTypesFileFormats(mediaType) {
        if (mediaType === "image") {
            return "image/*,.heic";
        } else if (mediaType === "video") {
            return "video/*";
        }
        console.warn(`Unknown Media type ${mediaType} received in MediaSelector component`);
        return "*";
    }

    const onMediaChange = (elementType, index, mediaId, mediaValue) => {
        updateElementMedia(index, mediaValue, elementType);
    };

    return (
        <>
            {!isViewOnly && rowMedia.length < limit &&
                <StyledMediaInput>
                    <label htmlFor={mediaType + "Upload"}>
                        <GSPSIcon
                            name={"PlusCircleFill"}
                            role="button"
                            color={getComputedStyle(document.body).getPropertyValue("--bs-primary")}
                        />

                    </label>
                    <input style={{visibility: "hidden", width: "1px"}} accept={getMediaTypesFileFormats(mediaType)}
                        id={mediaType + "Upload"}
                        type={"file"}
                        onChange={(e) => handleChangeImage(e, rowMedia.length)}
                        name={mediaType + "Upload"}
                    />
                </StyledMediaInput>
            }
            <Col>
                <StyledMediaContainer>
                    {rowMedia?.length > 0 && rowMedia.map((media, index) =>
                        (<div className="d-inline position-relative" key={`${elementType}_rowmedia_${index}`}>
                            <GSPSMediaSelector
                                key={mediaIdFromIndex(media.elementId, media.mediaId)}
                                id={mediaType == "image" ? index : `${rowMedia.issue_id}_${elementType}`}
                                controlSize={media.mediaId}
                                imageContainerSizePx={80}
                                imageContainerHeightSizePx={50}
                                currentImageSrc={
                                    media[`${mediaType}_value`] ? media[`${mediaType}_value`] :
                                        media.mediaValue ? media.mediaValue :
                                            undefined
                                }
                                isViewOnly={isViewOnly}
                                setValue={(id, value) => {
                                    onMediaChange(elementType, id, media.mediaId, value, media);
                                }}
                                mediaType={mediaType}>
                            </GSPSMediaSelector>
                            {!isViewOnly &&
                            <>
                                <GSPSIcon
                                    name="DashCircleFill"
                                    color="#EC979B"
                                    size="18"
                                    role="button"
                                    className="text-bg-red"
                                    style={{position: "absolute", right: "-9px", top: "-9px"}}
                                    onClick={() => {
                                        deleteMedia(media, index, mediaType);
                                    }
                                    }>
                                </GSPSIcon>
                            </>
                            }
                        </div>),
                    )}
                </StyledMediaContainer>
            </Col>
        </>);
};

AuditMedia.propTypes = {
    rowId: PropTypes.string.isRequired,
    rowMedia: PropTypes.array,
    updateElementMedia: PropTypes.func.isRequired,
    elementType: PropTypes.string,
    isViewOnly: PropTypes.bool,
    limit: PropTypes.number,
    mediaType: PropTypes.oneOf([
        "image",
        "video",
    ]),
};

export const AuditImages = ({
    rowId,
    rowImages,
    updateElementImages,
    elementType,
    limit,
    isViewOnly = true,
    deleteMedia,
}) => {
    return <AuditMedia
        rowId={rowId}
        rowMedia={rowImages}
        updateElementMedia={updateElementImages}
        elementType={elementType}
        limit={limit}
        isViewOnly={isViewOnly}
        mediaType={"image"}
        deleteMedia={deleteMedia}
    />;
};

AuditImages.propTypes = {
    rowId: PropTypes.string.isRequired,
    rowImages: PropTypes.array,
    updateElementImages: PropTypes.func.isRequired,
    deleteMedia: PropTypes.func.isRequired,
    elementType: PropTypes.string,
    isViewOnly: PropTypes.bool,
    limit: PropTypes.number,
};

export const AuditVideos = ({
    rowId,
    rowVideos,
    updateElementVideos,
    elementType,
    limit,
    isViewOnly = true,
    deleteMedia,
}) => {
    return <AuditMedia
        rowId={rowId}
        rowMedia={rowVideos}
        updateElementMedia={updateElementVideos}
        elementType={elementType}
        limit={limit}
        isViewOnly={isViewOnly}
        mediaType={"video"}
        deleteMedia={deleteMedia}
    />;
};

AuditVideos.propTypes = {
    rowId: PropTypes.string.isRequired,
    rowVideos: PropTypes.array,
    updateElementVideos: PropTypes.func.isRequired,
    deleteMedia: PropTypes.func.isRequired,
    elementType: PropTypes.string,
    isViewOnly: PropTypes.bool,
    limit: PropTypes.number,
};
