import jwtDecode from "jwt-decode";
import {makeGetRequest} from "Services/ServicesUtils";
import {API_USERS} from "Services/Endpoints";

export const fetchCurrentUser = (setCurrentUser) => {
    const token = localStorage.getItem("refresh_token");
    if (!token || token === "undefined") {
        setCurrentUser({});
        return;
    }

    const decoded = jwtDecode(token);
    makeGetRequest(`${API_USERS.users}${decoded.user_id}/`)
        .then((res) => {
            setCurrentUser(res);
        },
        )
        .catch(() => {
            localStorage.clear();
        });
};
