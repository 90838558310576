import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {distance} from "@turf/turf";
import {AuditTable} from "./AuditTable";

export const AuditMapSelectedLines = ({
    tidySelectedLines,
    updateElementImages,
    updateElementVideos,
    isViewOnly = true,
    deleteLineCall,
    editLineCall,
}) => {
    const calculateDimensions = (lines) => {
        return [...lines].map((obj) => {
            const startPoint = [obj.lng1, obj.lat1];
            const endPoint = [obj.lng2, obj.lat2];
            const distKm = distance(startPoint, endPoint);

            return {
                ...obj,
                dimensions: {
                    feet: (distKm * 3280.8399).toFixed(5),
                    yards: (distKm * 1093.6133).toFixed(5),
                },
            };
        });
    };

    const [linesWithDimensions, setLinesWithDimensions] = useState([]);

    useEffect(() => {
        setLinesWithDimensions(calculateDimensions(tidySelectedLines));
    }, [tidySelectedLines]);

    return (
        <AuditTable
            headerName={"Lines"}
            dimensionHeader={"Dimension (ft)"}
            isViewOnly={isViewOnly}
            items={linesWithDimensions}
            elementType={"line"}
            updateElementImages={updateElementImages}
            updateElementVideos={updateElementVideos}
            onDelete={deleteLineCall}
            onEdit={editLineCall}
        />
    );
};

AuditMapSelectedLines.propTypes = {
    tidySelectedLines: PropTypes.array,
    updateElementImages: PropTypes.func.isRequired,
    updateElementVideos: PropTypes.func.isRequired,
    isViewOnly: PropTypes.bool,
    deleteLineCall: PropTypes.func,
    editLineCall: PropTypes.func,
    updatedAuditLines: PropTypes.array,
};
