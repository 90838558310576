import React, {useEffect, useMemo, useRef, useState} from "react";
import {GSPSGoogleMap} from "Components/Map/GSPSGoogleMap";
import {Form} from "react-bootstrap";
import PropTypes from "prop-types";
import {map} from "lodash";
import {
    createSelectionOptions,
    makeDeleteRequest,
    makePostRequest,
    makeUpdateRequest,
} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {AuditMapSelectedIssues} from "Pages/Audits/AuditMapSelectedIssues";
import {AuditMapSelectedAreas} from "Pages/Audits/AuditMapSelectedAreas";
import {AuditMapSelectedLines} from "Pages/Audits/AuditMapSelectedLines";
import {AuditMapToolbar} from "Pages/Audits/AuditMapToolbar";
import {insertNotification} from "Utils/NotificationsUtils";
import GSPSAnnotationModal from "Components/Map/GSPSAnnotationModal";
import ConfirmationModal from "Components/Common/ConfirmationModal";
import {filterAndIndexItems} from "Utils/helpers";
import {useSelector} from "react-redux";

const AnnotationShapeIDMap = {
    ISSUE: {id: 1, query: "item"},
    LINE: {id: 2, query: "line"},
    AREA: {id: 3, query: "area"},
};

export const AuditMap = ({
    auditType,
    currentLocation,
    audit,
    addType,
    questions,
    removeType,
}) => {
    const [auditFilterOptionsList, setAuditFilterOptionsList] = useState([]);
    const [currentAnnotationShape, setCurrentAnnotationShape] = useState();
    const [currentAuditTypeItem, setCurrentAuditTypeItem] = useState();
    const [issuesSeverities, setIssuesSeverities] = useState([]);
    const [currentAuditType, setCurrentAuditType] = useState();
    const [annotationMarkers, setAnnotationMarkers] = useState([]);
    const [annotationLines, setAnnotationLines] = useState([]);
    const [annotationAreas, setAnnotationAreas] = useState([]);
    const [MapClicked, setMapClicked] = useState(false);
    const [resetDeleteDropdown, setResetDeleteDropdown] = useState(false);
    const [auditsFilter, setAuditsFilter] = useState({
        "items": [],
        "lines": [],
        "areas": [],
        "default": true,
    });
    const [clickedItem, setClickedItem] = useState(null);
    const [modalItemType, setModalItemType] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // states for confirmation modal
    const [showModal, setShowModal] = useState(false);
    const [actionToConfirm, setActionToConfirm] = useState(null);
    const {auditsTypesItems, severities} = useSelector((state)=> state.auditData);

    const tidySelectedIssues = useMemo(() => {
        return filterAndIndexItems(annotationMarkers, "items", auditsFilter);
    }, [annotationMarkers, auditsFilter]);

    const tidySelectedLines = useMemo(() => {
        return filterAndIndexItems(annotationLines, "lines", auditsFilter);
    }, [annotationLines, auditsFilter]);

    const tidySelectedAreas = useMemo(() => {
        return filterAndIndexItems(annotationAreas, "areas", auditsFilter);
    }, [annotationAreas, auditsFilter]);

    const annotationMarkersRef = useRef(annotationMarkers);

    useEffect(() => {
        createSelectionOptions(severities, setIssuesSeverities);
    }, [severities]);


    useEffect(() => {
        const updatedAuditsFilter = {
            items: getCheckedLabels(auditFilterOptionsList, "Items"),
            lines: getCheckedLabels(auditFilterOptionsList, "Lines"),
            areas: getCheckedLabels(auditFilterOptionsList, "Areas"),
        };

        setAuditsFilter(updatedAuditsFilter);
    }, [auditFilterOptionsList]);

    const getCheckedLabels = (optionsList, label) => {
        const option = optionsList.find((opt) => opt.label.toLowerCase() === label.toLowerCase());
        return option ? option.children.filter((child) => child.checked).map((child) => child.label) : [];
    };

    useEffect(() => {
        annotationMarkersRef.current = annotationMarkers;
    }, [annotationMarkers]);

    const initImages = (images, type, count) => {
        return images;
    };

    const initVideos = (videos, type, count) => {
        return videos;
    };

    const extractAuditTypes = (items) => {
        const auditTypes = items
            .map((item) => ({label: item.audit_type_item.audit_type.name, checked: true}))
            .filter((obj, index, self) =>
                self.findIndex((o) => o.label === obj.label) === index,
            );
        return auditTypes;
    };

    useEffect(() => {
        if (audit) {
            if (audit.audit_issues) {
                const issues = audit.audit_issues.map((issue) => ({
                    ...issue,
                    issue_images: initImages(issue.issue_images, "issue", annotationMarkers.length),
                    issue_videos: initVideos(issue.issue_videos, "issue", annotationMarkers.length),
                }));
                setAnnotationMarkers(issues);
                setAuditFilterOptionsList((prev) => {
                    const isAdded = prev.some((option) => option.label === "Items");
                    return isAdded ? prev : [...prev, {checked: true, label: "Items", children: extractAuditTypes(audit.audit_issues)}];
                });
            }
            if (audit.audit_lines) {
                const lines = audit.audit_lines.map((line) => ({
                    ...line,
                    line_images: initImages(line.line_images, "line", annotationLines.length),
                    line_videos: initVideos(line.line_videos, "line", annotationLines.length),
                }));
                setAnnotationLines(lines);
                setAuditFilterOptionsList((prev) => {
                    const isAdded = prev.some((option) => option.label === "Lines");
                    return isAdded ? prev : [...prev, {checked: true, label: "Lines", children: extractAuditTypes(audit.audit_lines)}];
                });
            }
            if (audit.audit_areas) {
                const areas = audit.audit_areas.map((area) => ({
                    ...area,
                    area_images: initImages(area.area_images, "area", annotationAreas.length),
                    area_videos: initVideos(area.area_videos, "area", annotationAreas.length),
                }));
                setAnnotationAreas(areas);
                setAuditFilterOptionsList((prev) => {
                    const isAdded = prev.some((option) => option.label === "Areas");
                    return isAdded ? prev : [...prev, {checked: true, label: "Areas", children: extractAuditTypes(audit.audit_areas)}];
                });
            }
        }
    }, [audit]);
    useEffect(() => {
        if (audit && questions.length > 0) {
            if (audit.audit_lines) {
                audit.audit_lines.forEach((line) => {
                    addType(line.audit_type_item.audit_type.id);
                });
            }
            if (audit.audit_issues) {
                audit.audit_issues.forEach((issue) => {
                    addType(issue.audit_type_item.audit_type.id);
                });
            }
            if (audit.audit_areas) {
                audit.audit_areas.forEach((area) => {
                    addType(area.audit_type_item.audit_type.id);
                });
            }
        }
    }, [audit, questions]);

    const addMarkerToState = (newMarker) => {
        return new Promise((resolve) => {
            setAnnotationMarkers((prevMarkers) => {
                const updatedMarkers = [...prevMarkers, newMarker];
                resolve(updatedMarkers);
                return updatedMarkers;
            });
        });
    };
    const deleteMedia = async (listOfMedia) => {
        for (const media of listOfMedia) {
            const type = media.video_sequence !== undefined ? "video" : "image";
            try {
                if (media.issue_id) {
                    await makeDeleteRequest(`${API_AUDITS.auditIssue}${media.issue_id}/${type}/`,
                        {[type + "_sequence"]: media[type + "_sequence"]});
                } else if (media.line_id) {
                    await makeDeleteRequest(`${API_AUDITS.auditLine}${media.line_id}/${type}/`,
                        {[type + "_sequence"]: media[type + "_sequence"]});
                } else if (media.area_id) {
                    await makeDeleteRequest(`${API_AUDITS.auditArea}${media.area_id}/${type}/`,
                        {[type + "_sequence"]: media[type + "_sequence"]});
                }
            } catch (error) {
                // insertNotification("Error", "Something went Wrong", "error");
                console.log(error);
            } finally {
                if (media.issue_id) {
                    const updatedItems = annotationMarkers.map((row) => {
                        if (row.id === media.issue_id) {
                            return {
                                ...row,
                                [`issue_${type}s`]: row[`issue_${type}s`].filter((m) =>
                                    m[type + "_sequence"] !== media[type + "_sequence"],
                                ),
                            };
                        }
                        return row;
                    });
                    setAnnotationMarkers([...updatedItems]);
                } else if (media.line_id) {
                    const updatedItems = annotationLines.map((row) => {
                        if (row.id === media.line_id) {
                            return {
                                ...row,
                                [`line_${type}s`]: row[`line_${type}s`].filter((m) =>
                                    m[type + "_sequence"] !== media[type + "_sequence"],
                                ),
                            };
                        }
                        return row;
                    });
                    updateAnnotationLines([...updatedItems]);
                    setIsModalOpen(false);
                } else if (media.area_id) {
                    const updatedItems = annotationAreas.map((row) => {
                        if (row.id === media.area_id) {
                            return {
                                ...row,
                                [`area_${type}s`]: row[`area_${type}s`].filter((m) =>
                                    m[type + "_sequence"] !== media[type + "_sequence"],
                                ),
                            };
                        }
                        return row;
                    });
                    updateAnnotationAreas([...updatedItems]);
                    setIsModalOpen(false);
                }
            }
        }
    };

    const deleteIssueCall = async (issueId) => {
        try {
            await makeDeleteRequest(`${API_AUDITS.auditIssue}${issueId}/`);
            const issueObject = annotationMarkersRef.current.find((issue) => issue.id === issueId);
            const issueAuditType = issueObject.audit_type_item.audit_type;
            removeType(issueAuditType.id);
            const updatedAnnotations = annotationMarkersRef.current.filter(
                (currentObject) => currentObject.id !== issueId);
            updateAnnotationLocations(updatedAnnotations);
            deleteTypeFromAuditsFilterIfExists(auditFilterOptionsList.find((option) => option.label === "Items"), issueAuditType.name);
            insertNotification("Success", "Item has been deleted.", "Success");
        } catch (error) {
            insertNotification("Error", `Can't delete audit Item. Reason: ${error.message}`, "error");
        }
    };
    const deleteLineCall = async (lineId) => {
        try {
            await makeDeleteRequest(`${API_AUDITS.auditLine}${lineId}/`);

            const lineObject = annotationLines.find((line) => line.id === lineId);
            const lineAuditType = lineObject.audit_type_item.audit_type;
            removeType(lineAuditType.id);

            const updatedAnnotations = annotationLines.filter((line) => line.id !== lineId);
            updateAnnotationLines(updatedAnnotations);
            deleteTypeFromAuditsFilterIfExists(auditFilterOptionsList.find((option) => option.label === "Lines"), lineAuditType.name);
            insertNotification("Success", "Line has been deleted.", "Success");
        } catch (error) {
            insertNotification("Error", `Can't delete audit line. Reason: ${error.message}`, "error");
        }
    };
    const deleteAreaCall = async (areaId) => {
        try {
            await makeDeleteRequest(`${API_AUDITS.auditArea}${areaId}/`);

            const areaObject = annotationAreas.find((area) => area.id === areaId);
            const areaAuditType = areaObject.audit_type_item.audit_type;
            removeType(areaAuditType.id);

            const updatedAnnotations = annotationAreas.filter((area) => area.id !== areaId);

            updateAnnotationAreas(updatedAnnotations);
            deleteTypeFromAuditsFilterIfExists(auditFilterOptionsList.find((option) => option.label === "Areas"), areaAuditType.name);
        } catch (error) {
            insertNotification("Error", `Can't delete audit area. Reason: ${error.message}`, "error");
        }
    };
    const deleteItem = (type, id) => {
        if (type == "issue") deleteIssueCall(id);
        else if (type == "line") deleteLineCall(id);
        else if (type == "area") deleteAreaCall(id);
    };

    const handleAuditTypeChange = (selectedAuditType) => {
        setCurrentAuditType(selectedAuditType);
    };

    const handleAuditTypeItemChange = (selectedAuditTypeItem) => {
        setCurrentAuditTypeItem(selectedAuditTypeItem);
    };

    const updateAuditFilterOptionsList = (auditFilterOptionsList, value) => {
        const updatedList = auditFilterOptionsList.map((option) => {
            const key = option.label.toLowerCase();
            const correspondingValue = value[key] ?? [];
            if (correspondingValue) {
                option.checked = correspondingValue?.length > 0;
                option.children = option.children.map((child) => ({
                    ...child,
                    checked: correspondingValue.includes(child.label),
                }));
            }
            return option;
        });
        return updatedList;
    };

    const handleFilterSelection = (value) => {
        try {
            const updatedAuditFilterOptionsList = updateAuditFilterOptionsList(auditFilterOptionsList, value);
            setAuditFilterOptionsList(updatedAuditFilterOptionsList);
            setAuditsFilter(value);
        } catch (e) {
            insertNotification("Error", `can't filter. Reason: ${e.message}`, "error");
        }
    };

    const addTypeToAuditsFilterIfNotExists = (auditTypes, typeName) => {
        if (auditTypes && !auditTypes.children.some((child) => child.label === typeName)) {
            setAuditFilterOptionsList((prevState) => {
                const updatedChildren = [...auditTypes.children, {label: typeName, checked: true}];
                const updatedAuditFilterOptionsList = prevState.map((option) => {
                    if (option.label === auditTypes.label) {
                        return {...option, checked: true, children: updatedChildren};
                    }
                    return option;
                });
                return updatedAuditFilterOptionsList;
            });
        }
    };

    const deleteTypeFromAuditsFilterIfExists = (auditTypes, typeName) => {
        const annotationMap = {
            Items: annotationMarkers,
            Lines: annotationLines,
            Areas: annotationAreas,
        };

        const annotationArray = annotationMap[auditTypes.label];

        const auditTypeOccurs = annotationArray.filter(
            (item) => item.audit_type_item.audit_type.name === typeName,
        ).length;

        setAuditFilterOptionsList((prevState) => {
            return prevState.map((option) => {
                if (option.label !== auditTypes.label) return option;

                if (annotationArray.length === 1) {
                    return {...option, checked: false, children: []};
                }

                if (auditTypeOccurs === 1) {
                    const updatedChildren = option.children.filter(
                        (child) => child.label !== typeName,
                    );
                    return {...option, checked: true, children: updatedChildren};
                }

                return option;
            });
        });
    };

    const saveIssue = (issue) => {
        const body = {
            audit_id: audit.id,
            address_lnglat: {
                lat: Number(issue.lat).toFixed(20),
                lng: Number(issue.lng).toFixed(20),
            },
            location: audit.location.id,
            severity: issue.severity ||
                severities?.find((severity) => severity.name === "Moderate")?.id,
            audit_type_item_id: currentAuditTypeItem.value,
        };
        return makePostRequest(`${API_AUDITS.auditIssue}`, body)
            .then((jsonRes) => {
                addType(currentAuditType.value);
                const newIssue = {
                    ...jsonRes,
                    issue_images: initImages(jsonRes.issue_images, "issue", annotationMarkers.length),
                    issue_videos: initVideos(jsonRes.issue_videos, "issue", annotationMarkers.length),
                };
                addMarkerToState(newIssue);
                onIssueClick(newIssue);

                const itemsAuditTypes = auditFilterOptionsList.find((option) => option.label === "Items");
                addTypeToAuditsFilterIfNotExists(itemsAuditTypes, newIssue.audit_type_item.audit_type.name);
                return jsonRes.id;
            })
            .catch((error) => {
                insertNotification("Error", `can't add audit Item. Reason: ${error.message}`, "error");
            });
    };
    const updateIssue = async (newIssue) => {
        makeUpdateRequest(`${API_AUDITS.auditIssue}${newIssue.id}/`, newIssue)
            .then(async (jsonRes) => {
                insertNotification("Success", "Audit Item has been Updated ..", "success");
                setAnnotationMarkers((prev) => prev.map((issue) => issue.id === newIssue.id ? {
                    ...jsonRes,
                    issue_images: initImages(jsonRes.issue_images, "issue", annotationMarkers.length),
                    issue_videos: initVideos(jsonRes.issue_videos, "issue", annotationMarkers.length),
                } : issue));
            }).catch((error) => {
                insertNotification("Error", `can't update audit item. Reason: ${error.message}`, "error");
            });
    };
    const saveLine = (line, lengthInFeet) => {
        const body = {
            audit_id: audit.id,
            address_lnglat1: {
                lat: Number(line.lat1),
                lng: Number(line.lng1),
            },
            address_lnglat2: {
                lat: Number(line.lat2),
                lng: Number(line.lng2),
            },
            length_feet: lengthInFeet.toFixed(2),
            location: audit.location,
            severity: line.severity || 1,
            audit_type_item_id: currentAuditTypeItem.value,
        };
        return makePostRequest(`${API_AUDITS.auditLine}`, body)
            .then((jsonRes) => {
                addType(currentAuditType.value);
                const newLine = {
                    ...jsonRes,
                    line_images: initImages(jsonRes.line_images, "line", annotationLines.length),
                    line_videos: initVideos(jsonRes.line_videos, "line", annotationLines.length),
                };
                updateAnnotationLines([...annotationLines, newLine]);
                onLineClick(newLine);

                const itemsAuditTypes = auditFilterOptionsList.find((option) => option.label === "Lines");
                addTypeToAuditsFilterIfNotExists(itemsAuditTypes, newLine.audit_type_item.audit_type.name);

                return jsonRes;
            })
            .catch((error) => {
                insertNotification("Error", `can't add audit line. Reason: ${error.message}`, "error");
            });
    };
    const saveArea = (area) => {
        const body = {
            audit_id: audit.id,
            vertices_address_lnglat: area.path,
            area_feet: area.areaInSquareFeet.toFixed(2),
            location: audit.location,
            severity: area.severity || 1,
            audit_type_item_id: currentAuditTypeItem.value,
        };
        return makePostRequest(`${API_AUDITS.auditArea}`, body)
            .then((jsonRes) => {
                addType(currentAuditType.value);
                const newArea = {
                    ...jsonRes,
                    area_images: initImages(jsonRes.area_images, "area", annotationAreas.length),
                    area_videos: initVideos(jsonRes.area_videos, "area", annotationAreas.length),
                };
                setAnnotationAreas([...annotationAreas, newArea]);
                onAreaClick(newArea);

                const itemsAuditTypes = auditFilterOptionsList.find((option) => option.label === "Areas");
                addTypeToAuditsFilterIfNotExists(itemsAuditTypes, newArea.audit_type_item.audit_type.name);

                return jsonRes;
            })
            .catch((error) => {
                insertNotification("Error", `can't add audit line. Reason: ${error.message}`, "error");
            });
    };

    const resetAuditTypeItem = () => {
        setCurrentAuditTypeItem(null);
    };

    const updateAnnotationLocations = (updatedAnnotations) => {
        setAnnotationMarkers(updatedAnnotations);
    };

    function deletedAnnotationLines() {
        setAnnotationLines([]);
    }
    const updateAnnotationLines = (annotationLines) => {
        setAnnotationLines([...annotationLines]);
    };

    const updateAnnotationAreas = (annotationAreas) => {
        setAnnotationAreas([...annotationAreas]);
    };

    function deletedAnnotationAreas() {
        setAnnotationAreas([]);
    }

    const updateIssueMarkerDescription = (id, description) => {
        const updatedAnnotations = map(annotationMarkers, function(a) {
            return a.id === id ? {
                ...a,
                description: description,
            } : a;
        });
        updateAnnotationLocations(updatedAnnotations);
    };

    const updateItem = (id, newItem, type) => {
        switch (type) {
            case "issue":
                setAnnotationMarkers(map(annotationMarkers, function(row) {
                    return row.id === id ? {
                        ...newItem,
                        issue_images: initImages(newItem.issue_images, "issue", annotationMarkers.length),
                        issue_videos: initVideos(newItem.issue_videos, "issue", annotationMarkers.length),
                    } : row;
                }));
                break;
            case "line":
                setAnnotationLines(map(annotationLines, function(row) {
                    return row.id === id ? {
                        ...newItem,
                        line_images: initImages(newItem.line_images, "line", annotationLines.length),
                        line_videos: initVideos(newItem.line_videos, "line", annotationLines.length),
                    } : row;
                }));
                break;
            case "area":
                setAnnotationAreas(map(annotationAreas, function(row) {
                    return row.id === id ? {
                        ...newItem,
                        area_images: initImages(newItem.area_images, "area", annotationAreas.length),
                        area_videos: initVideos(newItem.area_videos, "area", annotationAreas.length),
                    } : row;
                }));
                break;
            default:
                break;
        }
    };
    const updateAreaField = (id, field, val) => {
        const updatedAnnotationAreas = map(annotationAreas, function(row) {
            if (row.id === id) {
                row[field] = val;
            }
            return row;
        });
        setAnnotationAreas(updatedAnnotationAreas);
    };
    const updateMarkerSeverity = (id, severityID) => {
        const updatedAnnotations = map(annotationMarkers, function(a) {
            return a.id === id ? {
                ...a,
                severity: severityID,
                color: severities?.find((issueSeverity) =>
                    issueSeverity.id === Number(severityID))?.color_code,
            } :
                a;
        });

        updateAnnotationLocations(updatedAnnotations);
    };

    function onSelectAnnotationShape(number) {
        const obj = Object.values(AnnotationShapeIDMap).filter((obj) => obj.id === number)[0];
        setCurrentAnnotationShape(obj);
    }

    const handleDeleteSelection = (value) => {
        setResetDeleteDropdown(false);
        setActionToConfirm(value);
        setShowModal(true);
    };

    const onLineClick = (line) => {
        setModalItemType("line");
        setClickedItem(line);
        setIsModalOpen(true);
    };

    const onAreaClick = (Area) => {
        setModalItemType("area");
        setClickedItem(Area);
        setIsModalOpen(true);
    };
    const onIssueClick = (issue) => {
        setModalItemType("issue");
        setClickedItem(issue);
        setIsModalOpen(true);
    };

    const handleDropDownResetDone = () => {
        setMapClicked(false);
        setCurrentAuditType(null);
        setCurrentAnnotationShape(null);
    };

    const resetAuditTypesOptions = (annotationType) => {
        setAuditFilterOptionsList((currentAuditFilterOptionsList) => currentAuditFilterOptionsList.map((option) => {
            if (option.label === annotationType) {
                return {
                    ...option,
                    children: [],
                };
            }
            return {
                ...option,
                children: [...option.children],
            };
        }));
    };

    function deleteIssues() {
        const issueIds = annotationMarkers.map((marker) => marker.id);
        const issueTypeIds = annotationMarkers.map((marker) => marker.audit_type_item.audit_type.id);

        if (issueIds?.length > 0) {
            makeDeleteRequest(API_AUDITS.bulkDeleteIssues, {ids: issueIds})
                .then((response) => {
                    // WORK AROUND FOR 502 BAD GATEWAY ERROR ON SERVER
                    updateAnnotationLocations([]);
                    issueTypeIds.forEach((typeId) => {
                        removeType(typeId);
                    });
                    resetAuditTypesOptions("Items");
                })
                .catch(async (error) => {
                    const e = await error;
                    if (e?.message == "Bad Gateway") {
                        // WORK AROUND FOR 502 BAD GATEWAY ERROR ON SERVER
                        updateAnnotationLocations([]);
                        issueTypeIds.forEach((typeId) => {
                            removeType(typeId);
                        });
                        resetAuditTypesOptions("Items");
                    } else {
                        insertNotification("Error", `Error during bulk deletion of items (${e?.detail})`, "error");
                    }
                })
                .finally(() => {
                    setResetDeleteDropdown(true);
                });
        } else {
            insertNotification("info", "No items to delete!", "info");
        }
    }


    function deleteLines() {
        const lineIds = annotationLines.map((line) => line.id);
        const lineTypes = annotationLines.map((line) => line.audit_type_item.audit_type.id);

        if (lineIds?.length > 0) {
            makeDeleteRequest(API_AUDITS.bulkDeleteLines, {ids: lineIds})
                .then(() => {
                    // WORK AROUND FOR 502 BAD GATEWAY ERROR ON SERVER
                    deletedAnnotationLines();
                    lineTypes.forEach(type => {
                        removeType(type);
                    });
                    resetAuditTypesOptions("Lines");
                })
                .catch(async (error) => {
                    const e = await error;
                    if (e?.message == "Bad Gateway") {
                        // WORK AROUND FOR 502 BAD GATEWAY ERROR ON SERVER
                        deletedAnnotationLines();
                        lineTypes.forEach(type => {
                            removeType(type);
                        });
                        resetAuditTypesOptions("Lines");
                    } else {
                        insertNotification("Error", `Error during bulk deletion of lines (${e?.detail})`, "error");
                    }
                })
                .finally(() => {
                    setResetDeleteDropdown(true);
                });
        } else {
            insertNotification("info", "No Lines to delete!", "info");
        }
    }

    function deleteAreas() {
        const areaIDs = annotationAreas.map((area) => area.id);
        const areaTypes = annotationAreas.map((area) => area.audit_type_item.audit_type.id);

        if (areaIDs?.length > 0) {
            makeDeleteRequest(API_AUDITS.bulkDeleteAreas, {ids: areaIDs})
                .then(() => {
                    // WORK AROUND FOR 502 BAD GATEWAY ERROR ON SERVER
                    deletedAnnotationAreas();
                    areaTypes.forEach(type => {
                        removeType(type);
                    });
                    resetAuditTypesOptions("Areas");
                })
                .catch(async (error) => {
                    const e = await error;
                    // WORK AROUND FOR 502 BAD GATEWAY ERROR ON SERVER
                    if (e?.message == "Bad Gateway") {
                        deletedAnnotationAreas();
                        areaTypes.forEach(type => {
                            removeType(type);
                        });
                        resetAuditTypesOptions("Areas");
                    } else {
                        insertNotification("Error", `Error during bulk deletion of Areas (${e?.detail})`, "error");
                    }
                })
                .finally(() => {
                    setResetDeleteDropdown(true);
                });
        } else {
            insertNotification("info", "No Areas to delete!", "info");
        }
    }

    const handleConfirm = () => {
        switch (actionToConfirm) {
            case "deleteIssues":
                deleteIssues();
                break;
            case "deleteLines":
                deleteLines();
                break;
            case "deleteAreas":
                deleteAreas();
                break;
            default:
                break;
        }
        setShowModal(false);
    };

    return (
        <>
            <div>
                <Form>
                    <AuditMapToolbar
                        AnnotationShapeIDMap={AnnotationShapeIDMap}
                        currentAnnotationShape={currentAnnotationShape}
                        onSelectAnnotationShape={onSelectAnnotationShape}
                        onAuditTypeChange={handleAuditTypeChange}
                        onAuditTypeItemChange={handleAuditTypeItemChange}
                        handleDeleteSelection={handleDeleteSelection}
                        onResetAuditTypeItem={resetAuditTypeItem}
                        MapClicked={MapClicked}
                        resetDeleteDropdown={resetDeleteDropdown}
                        onDropDownReset={handleDropDownResetDone}
                        handleFilterSelection={handleFilterSelection}
                        AuditFilterOptionsList={auditFilterOptionsList}
                        updatedAuditFilter={auditsFilter}
                    />
                </Form>
            </div>

            {currentLocation ?
                <GSPSGoogleMap
                    audit={audit}
                    auditsFilter={auditsFilter}
                    currentAnnotationShape={currentAnnotationShape}
                    handleDropDownResetDone={handleDropDownResetDone}
                    currentAuditType={currentAuditType}
                    currentAuditTypeItem={currentAuditTypeItem}
                    setCurrentAnnotationShape
                    currentLocation={currentLocation}
                    setMapClicked={setMapClicked}
                    annotationAreas={annotationAreas}
                    annotationLines={annotationLines}
                    annotationMarkers={annotationMarkers}
                    saveIssue={saveIssue}
                    saveLine={saveLine}
                    saveArea={saveArea}
                    onLineClick={onLineClick}
                    onIssueClick={onIssueClick}
                    onAreaClick={onAreaClick}
                    updateIssue={updateIssue}
                    issuesSeveritiesWithColor={severities}
                /> : "detecting your location ... please wait."
            }
            {auditsFilter.items && auditsFilter.items.length > 0 && annotationMarkers.length > 0 && issuesSeverities &&
                <AuditMapSelectedIssues
                    audit={audit}
                    tidySelectedIssues={tidySelectedIssues}
                    deleteIssueCall={deleteIssueCall}
                    editIssueCall={onIssueClick}
                    issuesSeverities={issuesSeverities}
                    updateMarkerSeverity={updateMarkerSeverity}
                    updateElementImages={() => { }}
                    updateElementVideos={() => { }}
                    isViewOnly={false}
                    updateDescriptionCB={updateIssueMarkerDescription}
                />
            }
            {auditsFilter.lines && auditsFilter.lines.length > 0 && annotationLines.length > 0 &&
                <AuditMapSelectedLines
                    tidySelectedLines={tidySelectedLines}
                    updateElementImages={() => { }}
                    updateElementVideos={() => { }}
                    isViewOnly={false}
                    deleteLineCall={deleteLineCall}
                    editLineCall={onLineClick}
                />
            }
            {auditsFilter.areas && auditsFilter.areas.length > 0 && annotationAreas.length > 0 &&
                <AuditMapSelectedAreas
                    tidySelectedAreas={tidySelectedAreas}
                    updateElementImages={() => { }}
                    updateElementVideos={() => { }}
                    isViewOnly={false}
                    deleteAreaCall={deleteAreaCall}
                    editAreaCall={onAreaClick}
                    updateFieldCB={updateAreaField}
                />
            }
            <GSPSAnnotationModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                audit={audit}
                clickedItem={clickedItem}
                deleteMedia={deleteMedia}
                updateItem={updateItem}
                type={modalItemType}
                deleteItem={deleteItem}
                issuesSeverities={severities}
                typeItems={auditsTypesItems}
            />
            <ConfirmationModal
                show={showModal}
                onHide={() => setShowModal(false)}
                onConfirm={handleConfirm}
                action={actionToConfirm} />
        </>
    );
};


AuditMap.propTypes = {
    setAnnotationLocations: PropTypes.func.isRequired,
    setAnnotationLinesCoord: PropTypes.func.isRequired,
    setAnnotationAreasCoord: PropTypes.func.isRequired,
    auditType: PropTypes.object.isRequired,
    addType: PropTypes.func.isRequired,
    removeType: PropTypes.func.isRequired,
    audit: PropTypes.object,
    currentLocation: PropTypes.object,
    questions: PropTypes.array,
};

