import {useEffect, useState} from "react";
import {fetchCurrentUser} from "./UserUtils";

export const useCurrentUser = () => {
    const [currentUser, setCurrentUser] = useState({});

    useEffect(
        () => {
            fetchCurrentUser(setCurrentUser);
        },
        [],
    );

    currentUser.resetUser = () => {
        fetchCurrentUser(setCurrentUser);
    };

    return currentUser;
};
