import styled from "styled-components";

export const GSPSFormControlStyled = styled.div`
  & .css-cveggr-MuiListItemIcon-root {
    min-width: 30px;
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #8cfad0;
    border: 3px;
  }

  & .MuiOutlinedInput-root.Mui-focused {
    border-color: #8cfad0;
    padding: 9px;
  }

  & #issue_severity {
    display: flex;
    align-items: center;
    padding: 0px;
  }

  &:focus .MuiOutlinedInput-notchedOutline {
    border-color: #aefee6;
    border-width: 0.15rem;
  }

  & .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    padding-right: 0px;
    border: 1px solid #E8E7F5;
    margin-bottom: 16px;
    height: 44px;
  }

  & #audit_type_item_id {
    display: flex;
    align-items: center;
    padding: 0px;
  }
`;


export const DimensionsStyledContainer = styled.div`
    margin-bottom: 16px;
`