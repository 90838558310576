import styled from "styled-components";
import {Container} from "react-bootstrap";
import {Form} from "react-bootstrap";

export const GSPSContainer = styled(Container)`
    @media (min-width: 580px) {
        display: grid;
        gap: 4px;
    }
    padding: 0px;
`;

export const RequiredFormFieldIcon = styled.span`
    color: ${({theme}) => theme.color.danger} !important;
`;
export const StyledRemoveImageLabel = styled.label`
    text-decoration-line: underline;
    color: #E16E75;
`;

export const StyledUploadImageLabel = styled.label`
    text-decoration-line: underline;
    color: ${({theme}) => theme.color.primaryHover} !important; 

/*    &:hover {
        color: ${({theme}) => theme.color.primaryHover} !important; 
    }*/
`

export const StyledLabel = styled.label`
    color: ${({theme}) => theme.color.labelText}; 
`