import React from "react";
import PropTypes from "prop-types";

export const AutheniticationBanner = ({header, text}) => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center" >
            <img src="/SVP_light_bg.png" style={{width: "431px", height: "82px"}}
                className="mt-5"
            ></img>
            {/* <img src="/SVP_dark_bg.png" style={{width: "431px", height: "82px"}}
                className="mt-5"
            ></img> */}
            <h1 className="mt-5 title-md">{header}</h1>
            {text.length > 0 &&
                <p className="body-md">{text}</p>
            }
        </div>)
        ;
};

AutheniticationBanner.propTypes = {
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};
