import React from "react";
import PropTypes from "prop-types";
import {AuditTable} from "Pages/Audits/AuditTable";

export const AuditMapSelectedAreas = ({
    tidySelectedAreas,
    updateElementImages,
    updateElementVideos,
    isViewOnly = true,
    updateFieldCB = null,
    deleteAreaCall,
    editAreaCall,
}) => {

    return (
        <AuditTable
            headerName={"Areas"}
            dimensionHeader={"Dimension (ft²)"}
            isViewOnly={isViewOnly}
            items={tidySelectedAreas}
            elementType={"area"}
            onDelete={deleteAreaCall}
            onEdit={editAreaCall}
        />
    );
};

AuditMapSelectedAreas.propTypes = {
    tidySelectedAreas: PropTypes.array,
    updateElementImages: PropTypes.func.isRequired,
    updateElementVideos: PropTypes.func.isRequired,
    isViewOnly: PropTypes.bool,
    updateFieldCB: PropTypes.func,
    deleteAreaCall: PropTypes.func,
    editAreaCall: PropTypes.func,
};
