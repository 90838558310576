import {createGenericApi} from "./createGenericApi";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";

export const auditApi = createGenericApi({
    reducerPath: "auditApi",
    baseUrl: API_AUDITS.audits,
    tagTypes: ["Audit", "ArchivedAudit"],
    cacheTimeout: 10,
});

export const {
    useFetchItemsQuery: useFetchAuditsQuery,
    useArchiveItemMutation: useArchiveAuditMutation,
    useUnarchiveItemMutation: useUnarchiveAuditMutation,
    useDeleteItemMutation: useDeleteAuditMutation,
    useCloneItemMutation: useCloneAuditMutation,
    useAddItemMutation: useAddAuditMutation,
    useUpdateItemMutation: useUpdateAuditMutation,
} = auditApi;
