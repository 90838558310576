import React, {useState, useEffect} from "react";
import {Button, Checkbox, Divider, FormControlLabel, Skeleton} from "@mui/material";
import {FilterContainer} from "./GSPSFilterComponent.styles";
import PropTypes from "prop-types";


const GSPSFilterComponent = ({setOpen, title, options, onChange, updatedState}) => {
    const [checkedState, setCheckedState] = useState(options);

    const handleParentChange = (index) => (event) => {
        const newCheckedState = checkedState.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    checked: event.target.checked,
                    children: item.children.map((child) => ({
                        ...child,
                        checked: event.target.checked,
                    })),
                };
            }
            return item;
        });
        setCheckedState(newCheckedState);
    };

    const handleChildChange = (parentIndex, childIndex) => (event) => {
        const newCheckedState = checkedState.map((parent, idx) => {
            if (idx === parentIndex) {
                const newChildren = parent.children.map((child, cidx) =>
                    cidx === childIndex ? {...child, checked: event.target.checked} : child,
                );
                const allChildrenChecked = newChildren.every((child) => child.checked);
                return {
                    ...parent,
                    children: newChildren,
                    checked: allChildrenChecked,
                };
            }
            return parent;
        });
        setCheckedState(newCheckedState);
    };

    const handleParentIndeterminateState = (parentIndex) => {
        const parent = checkedState[parentIndex];
        const allChildrenUnchecked = parent.children.every((child) => !child.checked);
        const allChildrenChecked = parent.children.every((child) => child.checked);
        const someChildrenChecked = parent.children.some((child) => child.checked);
        return !allChildrenUnchecked && someChildrenChecked && !allChildrenChecked;
    };

    const handleCheckAll = () => {
        const newCheckedState = checkedState.map((parent) => ({
            ...parent,
            checked: true,
            children: parent.children.map((child) => ({
                ...child,
                checked: true,
            })),
        }));
        setCheckedState(newCheckedState);
    };

    const handleDeselectAll = () => {
        const newCheckedState = checkedState.map((parent) => ({
            ...parent,
            checked: false,
            children: parent.children.map((child) => ({
                ...child,
                checked: false,
            })),
        }));
        setCheckedState(newCheckedState);
    };

    const handleViewOnMap = () => {
        const returnedObject = {};
        let allParentsChecked = true;

        checkedState.forEach((parent) => {
            const selectedChildren = parent.children
                .filter((child) => child.checked)
                .map((child) => child.label);
            if (parent.checked || selectedChildren.length > 0) {
                returnedObject[parent.label.toLowerCase()] = selectedChildren.length > 0 ? selectedChildren : [];
            }

            if (!parent.checked) {
                allParentsChecked = false;
            }
        });

        if (allParentsChecked) {
            returnedObject.default = true;
        }
        setCheckedState(checkedState);
        onChange(returnedObject);
        setOpen(false);
    };


    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <FilterContainer>
            <div className="px-3 py-1 position-relative">
                <h1 className="title m-0">{title}</h1>
                <div>
                    <Button className="ps-0" variant={"text"} onClick={handleCheckAll}>
                        Select All
                    </Button>
                    <Button variant={"text"} onClick={handleDeselectAll}>
                        Deselect All
                    </Button>
                </div>
                <div className="row">
                    {checkedState.map((item, parentIndex) => (
                        <div className="col-4" key={parentIndex}>
                            <div>
                                {item.children.length > 0 && <FormControlLabel
                                    label={item.label}
                                    control={
                                        <Checkbox
                                            checked={item.checked}
                                            indeterminate={handleParentIndeterminateState(parentIndex)}
                                            onChange={handleParentChange(parentIndex)}
                                            size="small"
                                        />
                                    }
                                />}
                                <Divider />
                                <div className="mx-2 w-100">
                                    {item.children.map((child, childIndex) => (
                                        <FormControlLabel
                                            key={childIndex}
                                            label={child.label}
                                            control={
                                                <Checkbox
                                                    checked={child.checked}
                                                    onChange={handleChildChange(parentIndex, childIndex)}
                                                    size="small"
                                                />
                                            }
                                            className="d-block childOpt"
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <Button variant={"outlined"} className="me-3 cta-btn-secondary" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button className="cta-btn" variant={"contained"} onClick={handleViewOnMap}>
                        Update Map
                    </Button>
                </div>
            </div>
        </FilterContainer>
    );
};

GSPSFilterComponent.propTypes = {
    setOpen: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired,
        children: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            checked: PropTypes.bool.isRequired,
        })).isRequired,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    updatedState: PropTypes.object.isRequired,
};

export default GSPSFilterComponent;
