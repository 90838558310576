import MuiPagination from '@mui/material/Pagination';
import {gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector} from '@mui/x-data-grid';
import {useTheme} from 'styled-components';

const Pagination = ({page, onPageChange, className}) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const theme = useTheme()

    return (
        <MuiPagination
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}
            sx={{
                '.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': {
                    background: "#D8FFF0"
                }
            }}
        />
    );
}

const GSPSDataTablePagination = (props) => {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export default GSPSDataTablePagination