import styled from "styled-components";

export const AppStyle = styled.div`
    background: ${({theme}) => theme.color.bodyBackground};
    color: ${({theme}) => theme.color.textOnBg};
    position: relative;
    min-height: 100vh;
    margin: 0;
	font-family: "Poppins", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0px;
	font-feature-settings: "clig" off, "liga" off;
    text-align: left;

    a{
        text-decoration: none;
        color: ${({theme}) => theme.color.link};
        transition: all ease-in-out 0.5s !important;
    }

    a:hover{
        color: ${({theme}) => theme.color.linkHover};
    }

    h1,
    h2,
    h3,
    h4{
        font-weight: 600;
        margin-top:  0.5rem;
    }

    .headline-xl {
        font-size: 56px;
        line-height: 62px;
        letter-spacing: -1.5px;
    }
        
    .headline-lg {
        font-size: 48px;
        line-height: 58px;
        letter-spacing: -1px;
    }
    
    .headline-md {
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -1px;
    }
        
    .headline-sm {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.5px;
    }

    .title-md {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.5px;
    }

    .title-sm {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.5px;
    }

    .subtitle-lg {
        font-size: 25px;
        line-height: 24px;
        letter-spacing: -0.25px;
    }

    .subtitle-md {
        font-size: 19px;
        line-height: 22px;
        letter-spacing: -0.25px;
    }

    .subtitle-sm {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.25px;
    }

    .body-lg {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0px;
        font-weight: normal;
        font-family: "Noto", sans-serif !important;
    }

    .body-lg-bold {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0px;
        font-weight: 700;
        font-family: "Noto", sans-serif !important;
    }

    .body-md {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0px;
        font-weight: normal;
        font-family: "Noto", sans-serif !important;
    }

    .body-md-bold {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0px;
        font-weight: 700;
        font-family: "Noto", sans-serif !important;
    }

    .body-sm {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0px;
        font-weight: normal;
        font-family: "Noto", sans-serif !important;
    }

    .body-sm-bold {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0px;
        font-weight: 700;
        font-family: "Noto", sans-serif !important;
    }
        
    .label-md {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.25px;
        font-weight: 500;
        color: ${({theme}) => theme.color.secondaryText} !important;
    }
        
    .label-sm {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-weight: 500;
        color: ${({theme}) => theme.color.secondaryText} !important;
    }
        
    .menu-link-md {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.25px;
        font-weight: 500;
        color: ${({theme}) => theme.color.secondaryText}
    }
        
    .btn-link-md {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0px;
        font-weight: 700;
    }
        
    .btn-link-sm {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0px;
        font-weight: 700;
    }
        
    .icon-md {
        font-size: 24px;
    }
        
    .icon-sm {
        font-size: 18px;
    }
    
    .cta-btn {
        background-color: ${({theme}) => theme.color.ctaBg} !important;
        color: ${({theme}) => theme.color.cta} !important;
        transition: all ease-in-out 0.5s !important;
    }
    .cta-btn:hover {
        background-color: ${({theme}) => theme.color.ctaHoverBg} !important;
        color: ${({theme}) => theme.color.light} !important;
    }
    
    .cta-btn-secondary {
        background-color: ${({theme}) => theme.color.secondaryBg} !important;
        color: ${({theme}) => theme.color.textOnBg} !important;
        transition: all ease-in-out 0.5s !important;
    }
    .cta-btn-secondary:hover {
        background-color: ${({theme}) => theme.color.ctaSecondaryHoverBg} !important;
        color: ${({theme}) => theme.color.textOnBg} !important;
    }
        
    input,
    textarea,
    .MuiAutocomplete-inputRoot,
    select{
        border-color: ${({theme}) => theme.color.border} !important;
        transition: all ease-in-out 0.5s !important;
    }

    input:hover,
    textarea:hover,
    .MuiAutocomplete-inputRoot:hover,
    select:hover{
        border-color: #ADBDB9 !important;
    }
        
    input:focus,
    textarea:focus,
    .MuiAutocomplete-inputRoot:focus,
    .MuiInputBase-root:focus,
    .Mui-focused fieldset,
    select:focus{
        border-color: #02A783 !important;
        outline: none;
        box-shadow: none;
    }

    .Mui-selected,
    .MuiAutocomplete-option[aria-selected="true"],
    input[type="checkbox"]:checked,
    input[type="radio"]:checked {
        color: ${({theme}) => theme.color.light} !important;
        border-color: ${({theme}) => theme.color.dark} !important;
        background-color: #4CA2A8 !important;
    }

    .Mui-checked,
    .MuiCheckbox-indeterminate {
        color: #4CA2A8 !important;
    }

    .MuiDivider-root{
        background: ${({theme}) => theme.color.divider};
        height: 2px;
    }

    ::selection {
        color: ${({theme}) => theme.color.light};
        background-color: #4CA2A8;
    }

    /* Mobile styles */
    @media (max-width: 768px) {
        h1,
        h2,
        h3,
        h4 {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.5px;
        }

        h5,
        h6,
        span {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            font-weight: 700;
            font-family: "Poppins", sans-serif !important;
        }
    }
`;
