import React, {useState} from "react";
import {Col} from "react-bootstrap";
import PropTypes from "prop-types";
import {GSPSIcon} from "Icons/GSPSIcon";
import Modal from "react-bootstrap/Modal";

const FileSelectionStatus = {
    NONE_SELECTED: 0,
    INVALID_SELECTED: 1,
    VALID_SELECTION: 2,
};

function getMediaIconName(mediaType) {
    if (mediaType === "image") {
        return "CardImage";
    }
    return "CameraVideo";
}
function getMediaViewer(mediaType, src, maxWidthPx, maxHeightPx, includeControls = false) {
    if (typeof src === "object") {
        src = URL.createObjectURL(src);
    }
    const style = {
        maxWidth: maxWidthPx + "px",
        maxHeight: maxHeightPx + "px",
        width: "auto",
        height: "auto",
        borderRadius: "5px",
        objectFit: "contain",
    };

    if (mediaType === "image") {
        return (
            <img src={src} style={style} alt="Preview" />
        );
    } else if (mediaType === "video") {
        return <video src={src} controls={includeControls}
            style={{width: maxWidthPx + "px", height: maxHeightPx + "px", borderRadius: "5px"}}>
        </video>;
    }
    console.warn(`Unknown Media type ${mediaType} received in MediaSelector component`);
    return <></>;
}

function getMediaTypesFileFormats(mediaType) {
    if (mediaType === "image") {
        return "image/*,.heic";
    } else if (mediaType === "video") {
        return "video/*";
    }
    console.warn(`Unknown Media type ${mediaType} received in MediaSelector component`);
    return "*";
}

export const GSPSMediaSelector = ({
    id,
    controlSize,
    imageContainerSizePx,
    imageContainerHeightSizePx,
    setValue,
    currentImageSrc,
    mediaType,
    isViewOnly = false,
}) => {
    // States
    const [isPreviewDisplayed, setIsPreviewDisplayed] = useState(false);


    const handleClose = () => setIsPreviewDisplayed(false);
    const handleShow = () => setIsPreviewDisplayed(true);

    return <>
        <Col sm={controlSize} className="position-relative">
            <Modal className="modal show" id="exampleModal" role="dialog"
                backdrop="static"
                onHide={handleClose}
                show={isPreviewDisplayed}
                centered
            >
                <Modal.Dialog>
                    <Modal.Header closeButton><h5>Preview</h5> </Modal.Header>
                    <Modal.Body>
                        {getMediaViewer(mediaType, currentImageSrc, 450, 280, true)}
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
            <label
                style={{
                    width: imageContainerSizePx + "px",
                    height: imageContainerHeightSizePx + "px",
                    cursor: isViewOnly ? "default" : "pointer"}}
                onClick={handleShow}>
                {currentImageSrc && getMediaViewer(mediaType, currentImageSrc, imageContainerSizePx, imageContainerHeightSizePx )
                }
                {!currentImageSrc &&
                    <svg
                        xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                        className="bi bi-image" viewBox="0 0 16 16">
                        <GSPSIcon name={getMediaIconName(mediaType)}
                            className="m-1" />
                    </svg>
                }
            </label>
        </Col>
    </>;
};


GSPSMediaSelector.propTypes = {
    id: PropTypes.string.isRequired,
    controlSize: PropTypes.number,
    imageContainerSizePx: PropTypes.number.isRequired,
    imageContainerHeightSizePx: PropTypes.number.isRequired,
    setValue: PropTypes.func,
    currentImageSrc: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ],
    ),
    mediaType: PropTypes.oneOf([
        "image",
        "video",
    ]),
    isViewOnly: PropTypes.bool,
};
