import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {API_ACCOUNTS} from "Models/Account/AccountEndpoints";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {API_LOCATIONS} from "Models/Location/LocationEndpoints";
import {API_USERS} from "Services/Endpoints";
import {makeGetRequest} from "Services/ServicesUtils";

const getLocalStorageData = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
};

const setLocalStorageData = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

export const fetchAllData = createAsyncThunk(
    "data/fetchAllData",
    async (_, thunkAPI) => {
        try {
            const cachedData = getLocalStorageData("auditData");
            if (cachedData) {
                return cachedData;
            }
            const [severities, questions, auditTypes, auditsTypesItems, auditStatuses, users, statusTypes, accountsDropdown] = await Promise.all([
                makeGetRequest(API_AUDITS.issuesSeverities + "?no_page"),
                makeGetRequest(API_AUDITS.questions),
                makeGetRequest(API_AUDITS.auditsTypes + "?no_page"),
                makeGetRequest(API_AUDITS.auditsTypesItems),
                makeGetRequest(API_AUDITS.auditStatuses + "?no_page"),
                makeGetRequest(API_USERS.users + "?no_page"),
                makeGetRequest(API_LOCATIONS.statusTypes + "?no_page"),
                makeGetRequest(API_ACCOUNTS.accountsDropdown + "?no_page"),
            ]);
            const dataToCache = {
                severities,
                questions,
                auditTypes,
                auditStatuses,
                users,
                auditsTypesItems,
                statusTypes,
                accountsDropdown,
            };
            setLocalStorageData("auditData", dataToCache);
            return dataToCache;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

const initialState = {
    severities: [],
    questions: [],
    auditTypes: [],
    auditStatuses: [],
    auditsTypesItems: [],
    users: [],
    statusTypes: [],
    accountsDropdown: [],
    status: "idle",
    error: null,
};

const dataSlice = createSlice({
    name: "data",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.severities = action.payload.severities;
                state.questions = action.payload.questions;
                state.auditTypes = action.payload.auditTypes;
                state.auditStatuses = action.payload.auditStatuses;
                state.auditsTypesItems = action.payload.auditsTypesItems;
                state.users = action.payload.users;
                state.statusTypes = action.payload.statusTypes;
                state.accountsDropdown = action.payload.accountsDropdown;
            })
            .addCase(fetchAllData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export default dataSlice.reducer;
