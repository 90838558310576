import React from "react";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

export const AccountsManager = () => {
    const navigate = useNavigate();
    const changeRoute = () =>{
        const path = "/accounts/add";
        navigate(path);
    };
    return (
        <>
            <h2> Manage Accounts </h2>
            <Button onClick={() => changeRoute()}>
                Add New Account
            </Button>

        </>
    );
};
