import styled from "styled-components";
import Switch from '@mui/material/Switch';

export const GSPSSwitchStyled = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-thumb': {
        color: theme.color.SwitchKnobColor,
        scale: "0.7"
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: theme.color.switchDisabled
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.color.switchEnabled,
        transform: "translateX(-1px) scaleY(1.3) scaleX(1.1)"
    },
}));
