import styled from "styled-components";
import {Container, Form} from "react-bootstrap";
import {Link} from "react-router-dom";

export const FormContainer = styled(Container).attrs(() => ({
    className: "pt-3 d-flex justify-content-center align-items-center",
}))``;

export const CustomDivWidth67 = styled.div`
    width: 67%;
`;

export const LoginForm = styled(Form)`
    display: grid;
    gap: 24px;
`;

export const LoginDataContainerStyled = styled.div`
    display: grid;
    gap: 20px;
`;

export const LoginFormGroup = styled(Form.Group).attrs(() => ({
    className: "",
}))``;

export const GSPSPasswordContainerStyled = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    padding-right: 1rem;
`