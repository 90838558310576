import React, {useCallback, useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import {makePostRequest} from "Services/ServicesUtils";
import {useParams} from "react-router-dom";
import {API_PAYMENT} from "Models/Checkout/CheckoutEndpoints";

import {SignupForm} from "Pages/Singup/SignupForm";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const Checkout = () => {
    const [customerData, setCustomerData] = useState();
    const {priceLookupKey} = useParams();
    const fetchClientSecret = useCallback(() => {
        return makePostRequest(API_PAYMENT.checkoutSession, {
            "price_lookup_key": priceLookupKey,
            "customer_data": customerData,
        })
            .then((data) => data.client_secret);
    }, [customerData]);
    const options = {fetchClientSecret};
    return (customerData ? (
        <div id="checkout">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>) : (
        <SignupForm onNext={setCustomerData} />
    )
    );
};
