import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {AuditViewer} from "Pages/Audits/AuditViewer";

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export const PublicAuditViewer = () => {
    const publicAudit = API_AUDITS.publicAudits;
    const {auditID} = useParams();
    const [audit, setAudit] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAudit = async () => {
            try {
                const response = await fetch(`${API_AUDITS.publicAudits}${auditID}/`);
                if (response.status === 404) {
                    setError("This audit record has expired.");
                } else if (!response.ok) {
                    setError("An error occurred while fetching the audit record.");
                } else {
                    const data = await response.json();
                    setAudit(data);
                }
            } catch (err) {
                setError("An error occurred while fetching the audit record.");
            }
        };

        fetchAudit();
    }, [auditID]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!audit) {
        return <div>Loading...</div>;
    }


    return (
        <AuditViewer
            publicAudit={publicAudit}
            hideEmailPopup={true}
            PublicRecord={true}
        />
    );
};
